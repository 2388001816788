<template>
  <div id="room-browser-category-view" class="room-browser-category-view">
    <Loader v-if="showLoader" data-testid="loader" />

    <div v-else data-testid="content-category" class="category-all-wrapper">
      <div class="category-header">
        <h1 class="category-name">
          {{ currentCategory.name }}
        </h1>
        <LocalFilters v-if="currentCategory.filters || currentCategory.sorting"
                      data-testid="local-filters"
                      :filters="currentCategory.filters"
                      :sorting="currentCategory.sorting"
                      @changeSort="changeSort"
                      @changeFilters="changeFilters"
                      @resetFiltersAndSort="resetFiltersAndSort"
        />
        <button v-if="isRecentlyVisited"
                data-testid="operation-button"
                class="category-clear-btn link-common"
                @click="showClearCategoryDialog"
        >
          {{ operationName }}
        </button>
        <Dialog id="category-clear-dialog"
                :visible.sync="clear_category"
                :closable="false"
                :position="dialogPos"
        >
          <template #header>
            <h3>{{ $t('message.clear_category_dialog.title') }}</h3>
            <div class="close-icon" @click="showClearCategoryDialog" />
          </template>

          <div class="dialog-content">
            {{ $t('message.clear_category_dialog.content') }}
          </div>

          <template #footer>
            <Button :label="$t('message.dialog.no')" class="p-button-text" @click="showClearCategoryDialog" />
            <Button :label="$t('message.dialog.yes')" autofocus @click="clearCategory()" />
          </template>
        </Dialog>
      </div>
      <div v-if="!presetEmpty" data-testid="category-preset" class="category-preset">
        <category-preset-item v-for="(preset, index) in categoryPresetList"
                              :key="index"
                              :category-preset="preset"
                              :selected-category-preset="selectedPreset"
                              @changePreset="changePreset"
        />
      </div>
      <div data-testid="rooms-container" class="rooms-container">
        <Room v-for="room in categoryRooms"
              :key="room.id"
              :room="room"
              :category="currentCategory"
              :is-recently-visited="isRecentlyVisited"
        />
        <RoomPromoteCard v-if="isPromotedCategory" data-testid="promoted-card" class="room-card-container" />
        <transition name="fade">
          <div v-if="isLoadMore" class="loader-wrap">
            <Loader />
          </div>
        </transition>
      </div>
      <CategoryEmptyPlaceholder v-if="isCategoryPlaceholder" data-testid="placeholder" :category-id="currentCategory.id" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader";
import LocalFilters from "@/room_browser/components/LocalFilters";
import CategoryPresetItem from "@/room_browser/components/category/CategoryPresetItem";
import CategoryEmptyPlaceholder from "@/room_browser/components/category/CategoryEmptyPlaceholder";
import Room from "@/room_browser/components/room/Room";
import RoomPromoteCard from "@/room_browser/components/room/RoomPromoteCard";
import { updateRooms, checkIsFollowingCategory, checkIsRecentlyCategory, checkIsPromotedCategory } from "@/room_browser/services/categories"
import _cloneDeep from 'lodash/cloneDeep';

export default {
  name: "Category",

  components: {
    Loader,
    LocalFilters,
    CategoryPresetItem,
    CategoryEmptyPlaceholder,
    Room,
    RoomPromoteCard,
  },

  props: {
    standalone: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      isLoadMore: false,
      offset: 0,
      clear_category: false,
      scrollReady: true,
      currentCategory: {},
      showPlaceholder: false
    };
  },

  computed: {
    ...mapGetters({
      showLoader: 'showLoader',
      globalParamsInitialized: 'globalParamsInitialized',
      globalFilters: 'globalFilters',
      category: 'currentCategory',
      discoverCategoriesList: 'discoverCategoriesList'
    }),

    isRecentlyVisited() {
      return checkIsRecentlyCategory(this.currentCategory) && this.hasRooms
    },

    isPromotedCategory() {
      return checkIsPromotedCategory(this.currentCategory)
    },

    isCategoryPlaceholder() {
      return !this.isPromotedCategory && Object.keys(this.currentCategory).length && this.showPlaceholder
    },

    needLoadMore() {
      if (this.currentCategory) {
        return this.currentCategory.request_next_rooms_from < this.currentCategory.total_rooms
      }
      return false
    },

    hasRooms() {
      return this.currentCategory.rooms.length > 0;
    },

    categoryRooms() {
       return this.currentCategory.rooms || []
    },

    categoryPresetList() {
      return this.currentCategory.category_preset.list || []
    },

    selectedPreset() {
      return this.currentCategory.category_preset.value || null
    },

    presetEmpty() {
      return !(this.currentCategory.category_preset && this.currentCategory.category_preset.list.length)
    },

    operationName() {
      return this.currentCategory.operations[0] ? this.currentCategory.operations[0].name : ''
    },

    dialogPos() {
      const app = this.$appName || ''

      return app === 'videochat' ? 'top' : 'topright';
    },
  },

  watch: {
    category: {
      handler: function(newValue) {
        this.currentCategory = newValue
        this.showPlaceholder = !(newValue.rooms && newValue.rooms.length)
        this.offset = newValue.request_next_rooms_from
      },
      deep: true
    },
    $route() {
      this.currentCategory = {}
      this.updateLoader(true)
      this.load();
    }
  },

  created() {
    if (this.standalone) {
      this.$root.$refs['currentComponent'] = this
    } else {
      this.$root.$refs['innerComponent'] = this
    }

    this.currentCategory = _cloneDeep(this.category);
    this.offset = this.currentCategory.request_next_rooms_from ? this.currentCategory.request_next_rooms_from : 0

    if (this.globalParamsInitialized === true && this.standalone) {
      this.updateLoader(true)
      this.load()
    }
  },

  mounted() {
    window.addEventListener('scroll', this.loadMore, false)
  },

  destroyed() {
    window.removeEventListener('scroll', this.loadMore, false)
    //this.setCurrentCategory({})
    this.$root.$refs['innerComponent'] = null
  },

  methods: {
    ...mapActions({
      updateLoader: 'updateLoader',
      setCurrentCategory: 'setCurrentCategory',
      setRoomBrowserData: 'setRoomBrowserData',
      updateFollowedFlag: 'updateFollowedFlag',
    }),

    load() {
      this.offset = 0
      this.getCategory()
    },

    changeFollowed(data) {
      if (checkIsFollowingCategory(this.currentCategory) && !data.user_followed) {
        this.currentCategory.rooms = this.currentCategory.rooms.filter(room => room.id !== data.room_id)
      } else {
        this.currentCategory.rooms = updateRooms(this.currentCategory.rooms, data)
        this.updateFollowedFlag(true)
      }
    },

    showClearCategoryDialog() {
      this.clear_category = !this.clear_category
    },

    clearCategory() {
      this.updateLoader(true)
      this.$roomBrowserService.execCategoryOperation(
          {
            category_id: this.currentCategory.id,
            operation_id: this.currentCategory.operations[0] ? this.currentCategory.operations[0].id : 1,
            global_filters: this.globalFilters,
          },
          (response) => {
            this.setCurrentCategory(response)
            this.showPlaceholder = !response.rooms.length
            this.clear_category = false
            this.updateLoader(false)
          },
          (response) => {
            console.log('Category.vue. clearCategory. error: ', response);
            this.clear_category = false
            this.updateLoader(false)
          }
      )
    },

    loadMore() {
      let scrollHeight = Math.max(
          document.body.scrollHeight, document.documentElement.scrollHeight,
          document.body.offsetHeight, document.documentElement.offsetHeight,
          document.body.clientHeight, document.documentElement.clientHeight
      );
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
      let bottomOfWindow = scrollTop + window.innerHeight === scrollHeight;

      if (bottomOfWindow && this.needLoadMore && this.scrollReady) {
        this.scrollReady = false
        this.isLoadMore = true
        this.getCategory(true)
      }
    },

    mergingRooms(rooms) {
      this.currentCategory.rooms = rooms.concat()
      for (let i = 0; i < this.currentCategory.rooms.length; ++i ) {
        for (let j = i + 1; j < this.currentCategory.rooms.length; ++j ) {
          if (this.currentCategory.rooms[i].id === this.currentCategory.rooms[j].id) this.currentCategory.rooms.splice(--j, 1)
        }
      }
      this.scrollReady = true
      return this.currentCategory.rooms
    },

    changePreset(preset) {
      this.currentCategory.rooms = []
      this.currentCategory.category_preset.value = preset.id
      this.showPlaceholder = false
      this.load()
    },

    changeFilters(filters) {
      this.currentCategory.filters.values = filters
      this.load()
    },

    changeSort(sort_id) {
      this.currentCategory.sorting.value = sort_id
      this.load()
    },

    resetFiltersAndSort(params) {
      this.currentCategory.sorting.value = params.sort_id
      this.currentCategory.filters.values = params.filters
      this.load()
    },

    getCategory(is_load_more) {
     // this.updateLoader(true)
      this.$roomBrowserService.getCategory(
          {
            category_id: Number(this.$route.params.id),
            global_filters: this.globalFilters,
            category_preset: this.currentCategory.category_preset ? this.currentCategory.category_preset.value : null,
            category_filters: this.currentCategory.filters && this.currentCategory.filters.values ? this.currentCategory.filters.values : [],
            category_sorting:  this.currentCategory.sorting && this.currentCategory.sorting.value ? this.currentCategory.sorting.value : null,
            offset: this.offset
          },
          (response) => {
            this.showPlaceholder = !response.rooms.length
            this.setCurrentCategory(response)
            let rooms = this.currentCategory.rooms
            this.currentCategory = response;
            if (is_load_more) {
              this.mergingRooms(rooms.concat(this.currentCategory.rooms))
              this.isLoadMore = false
            }
            this.updateLoader(false)
          },
          (response) => {
            console.log('Category.vue. getCategory. error: ', response);
            this.isLoadMore = false
            this.updateLoader(false)
          }
      );
    }
  }
}
</script>

<style scoped lang="scss">
.room-browser-category-view {
  height: 100%;
  min-height: 500px;
  position: relative;
}

.category-all-wrapper {
  .category-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0;
    .category-name {
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      color: #333333;
    }
    .category-clear-btn {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.0015em;
      color: #2E6DCB;
      &:hover {
        color: #0E57C4;
      }
    }
    .dialog-content {
      width: 235px;
    }
  }

  .category-preset {
    display: flex;
    flex-wrap: wrap;
    margin: 25px 0;
    gap: 8px;
  }
  .rooms-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    margin-left: -12px;
    &:not(:empty) {
      min-height: 200px;
    }
    &:empty {
      min-height: auto;
    }
    .loader-wrap {
      height: 250px;
      width: 100%;
      background-color: rgba(255, 255, 255);
      position: relative;
    }
    .room-card-container {
      margin-bottom: 24px;
      margin-left: 12px;
      @media screen and (max-width: 1130px) {
        margin-bottom: 12px;
      }
    }

  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
