import { /*mapActions,*/ mapGetters } from "vuex";

export const roomBrowserMixin = {
    computed: {
        ...mapGetters({
            isUserLoggedIn: 'isUserLoggedIn'
        }),
        isHomePage() {
            return this.$route.name === 'home'
        },
    },
}
