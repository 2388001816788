<template>
  <div>
    <div v-for="(category, index) in categories" :key="category.id">
      <CategoryCarousel :category="category" />
      <div v-if="showInContentAds(index)" class="ads ads-horizontal">
        <room-browser-in-content :slot-key="getAdSlotKey(index)" class="slot-common" />
        <room-browser-in-content-mobile :slot-key="getAdSlotKey(index)" class="slot-mobile" />
      </div>
    </div>
  </div>
</template>

<script>
import CategoryCarousel from "@/room_browser/components/category/CategoryCarousel";
import { mapGetters } from "vuex";
import RoomBrowserInContent from "@/components/Ads/RoomBrowser/RoomBrowserInContent.vue";
import RoomBrowserInContentMobile from "@/components/Ads/RoomBrowser/RoomBrowserInContentMobile.vue";

export default {
  name: "DiscoverAllCategories",

  components: {
    RoomBrowserInContentMobile,
    RoomBrowserInContent,
    CategoryCarousel,
  },

  data() {
    return {
      categories: [],
      advCount: 0
    }
  },

  computed: {
    ...mapGetters({
      discoverCategories: 'discoverCategories'
    }),
  },

  watch: {
    discoverCategories: {
      handler: function(newValue) {
        this.categories = newValue
      },
      deep: true
    }
  },

  created() {
    this.categories = this.discoverCategories
  },

  methods: {
    showInContentAds(index) {
      return (index + 1) % 4 === 0 && this.$appName === 'videochat';
    },
    getAdSlotKey(index) {
      return (index + 1) / 4;
    }
  }
}
</script>

<style scoped lang="scss">
.ads-horizontal {
  margin: 15px auto;

  & > div {
    display: none;

    &.slot-mobile {
      @media screen and (max-width: 700px) {
        display: block;
      }
    }

    &.slot-common {
      @media screen and (min-width: 700px) {
        display: block;
      }
      @media screen and (max-width: 700px) {
        display: none;
      }
    }




  }
}
</style>
