import Vue from "vue";

export default {
    state: {
        show_loader: true,
        globalParamsInitialized: false,
        global_params: {
            client_language: "en",
            show_adult_switch: true,
            global_filters: {
                enable_adult_switch: false,
                language_id: 0,
            },
            share_room_allowed: false,
            total_rooms: 0,
            is_logged: true
        },

        languages_list: [{id: 0, name: "All Languages", code: "all"}],
        current_language: {id: 0, name: "All Languages", code: "all"},

        categories: [
            {
                id: null,
                type: "",
                name: "",
                description: "",
                sorting: {
                    list: [
                        {id: null, name: ""}
                    ],
                    value: null
                },
                filters: {
                    list: [
                        {
                            id: null,
                            name: "",
                            values: [
                                {id: null, name: ""}
                            ]
                        },
                    ],
                    values: [
                        {filter_id: null, value_id: null}
                    ]
                },
                category_preset: {
                    list: [
                        {id: null, name: ""}
                    ],
                    value: null
                },
                show_on_home_page: true,
                show_on_discover_page: false,
                total_rooms: null,
                request_next_rooms_from: null,
                operations: [
                    {id: null, name: ""},
                ],
                rooms: [
                    {
                        id: null,
                        nick: "",
                        alias: "",
                        nick_color: '',
                        avatar_id: null,
                        adult: false,
                        deaf: false,
                        audio_only: false,
                        supports_hd_quality: true,
                        has_password_protection: false,
                        users_in_room: null,
                        users_with_cam: null,
                        fans: null,
                        user_followed: false,
                        popular_level: null,
                        gift_points: null,
                        achievement_level: null,
                        subscription_id: null,
                        total_topics: null,
                        topics: [],
                        category_color: "",
                        category_icon_id: null,
                        operations: [
                            {id: null, name: ""},
                        ],
                    }
                ]
            }
        ],
        discover_categories_list: [],
        current_category: {},

        update_followed: false,
        update_recently: false,

        search_result: {},
        search_chips: [],

        highlightElement: {
            languageButton: false
        }

    },

    getters: {
        /* Global state */
        showLoader: (state) => {
            return state.show_loader
        },
        globalParamsInitialized: (state) => {
            return state.globalParamsInitialized;
        },
        globalParams: (state) => {
            return state.global_params
        },
        globalFilters: (state) => {
            return state.global_params.global_filters
        },
        showAdultSwitch: (state) => {
            return state.global_params.show_adult_switch;
        },
        enableAdultSwitch: (state) => {
            return state.global_params.global_filters.enable_adult_switch;
        },
        showShareRoom: (state) => {
            return state.global_params.share_room_allowed;
        },
        isFollowedUpdated: (state) => {
            return state.update_followed
        },
        isRecentlyUpdated: (state) => {
            return state.update_recently
        },
        languageList: (state) => {
            // if (!state.languages_list.length) {
            //   state.languages_list.push(state.current_language)
            // } else {
            //   return state.languages_list
            // }
            return state.languages_list
        },
        currentLanguage: (state) => {
            if (state.languages_list.length) {
                let foundedLang = state.languages_list.find(lang => lang.id === state.global_params.global_filters.language_id)
                if (foundedLang) {
                    return state.current_language = foundedLang
                }
            }
            return state.current_language
        },
        highlightLangButton: (state) => {
            return state.highlightElement.languageButton
        },

        /* Category */
        allCategories: (state) => {
            return state.categories;
        },
        homeCategories: (state) => {
            return state.categories.filter(category => category.show_on_home_page);
        },
        discoverCategories: (state) => {
            return state.categories.filter(category => category.show_on_discover_page);
        },
        discoverCategoriesList: (state) => {
            return state.discover_categories_list
        },
        currentCategory: (state) => {
            return state.current_category
        },

        /* Search */
        searchResult: (state) => {
            return  state.search_result
        },
        searchChips: state => {
            return state.search_chips
        },
        totalRooms: state => state.global_params.total_rooms,
        isUserLoggedIn: state => state.global_params.is_logged,
    },

    mutations: {
        UPDATE_LOADER(state, params) {
            state.show_loader = params
        },
        UPDATE_PARAMS_INIT(state, params) {
            state.globalParamsInitialized = params
        },
        SET_GLOBAL_PARAMS(state, params) {
            for (const [key, value] of Object.entries(params)) {
                state.global_params[key] = value;
            }
        },
        SET_GLOBAL_FILTERS(state, params) {
            state.global_params.global_filters = params;
        },
        SET_ADULT_GLOBAL_FILTER(state, flag) {
            state.global_params.global_filters.enable_adult_switch = flag;
        },
        SET_LANGUAGE_GLOBAL_FILTER(state, language) {
            state.global_params.global_filters.language_id = language;
        },
        SET_ROOM_BROWSER_DATA(state, params) {
            for (const [key, value] of Object.entries(params)) {
                state[key] = value;
            }
        },
        SET_LANGUAGES_LIST(state, list) {
            state.languages_list = list
        },
        SET_HIGHLIGHT_LANG_BUTTON(state, flag) {
            state.highlightElement.languageButton = flag
        },

        UPDATE_FOLLOWED_FLAG(state, flag) {
            state.update_followed = flag
        },
        UPDATE_RECENTLY_VISITED_FLAG(state, flag) {
            state.update_recently = flag
        },

        UPDATE_CATEGORIES(state, categories) {
            state.categories = categories
        },
        REPLACE_CATEGORY(state, category) {
            let indexOfCategory = state.categories.findIndex(el => el.id === category.id);
            Vue.set(state.categories, indexOfCategory, category)
        },
        SET_CURRENT_CATEGORY(state, category) {
            state.current_category = category
        },
        UPDATE_CATEGORY_ROOMS_DATA(state, params) {
            state.categories[params.index].rooms.forEach((room, i) => {
                if (room.id !== params.room_id) {
                    return
                }

                for (let [key, value] of Object.entries(params.data)) {
                    room[key] = value;
                }

                state.categories[params.index].rooms[i] = room;
            })
        },
        REMOVE_CATEGORY_ROOM(state, params) {
            state.categories[params.index].rooms = state.categories[params.index].rooms.filter(room => room.id !== params.room_id)
        },

        SET_SEARCH_RESULT(state, results) {
            state.search_result = results;
        },
        UPDATE_SEARCH_QUERY(state, query) {
            state.search_result.search_query = query
        },
        UPDATE_SEARCH_CHIPS(state, data) {
            state.search_chips = data
        },
    },

    actions: {
        updateLoader({ commit }, flag) {
            commit('UPDATE_LOADER', flag)
        },
        updateParamsInit({ commit }, flag) {
            commit('UPDATE_PARAMS_INIT', flag)
        },
        setGlobalParams({commit}, params) {
            commit('SET_GLOBAL_PARAMS', params)
        },
        setGlobalFilters({commit}, filters) {
            commit('SET_GLOBAL_FILTERS', filters)
        },
        setAdultGlobalFilter({commit}, flag) {
            commit('SET_ADULT_GLOBAL_FILTER', flag)
        },
        setLanguageGlobalFilter({commit}, language) {
            commit('SET_LANGUAGE_GLOBAL_FILTER', language)
        },
        setRoomBrowserData({commit}, data) {
            commit('SET_ROOM_BROWSER_DATA', data);
        },
        setLanguagesList({commit}, list) {
            commit('SET_LANGUAGES_LIST', list)
        },
        updateFollowedFlag({commit}, flag) {
            commit('UPDATE_FOLLOWED_FLAG', flag)
        },
        updateRecentlyVisitedFlag({commit}, flag) {
            commit('UPDATE_RECENTLY_VISITED_FLAG', flag)
        },
        setHighlightLangButton({commit}, flag) {
            commit('SET_HIGHLIGHT_LANG_BUTTON', flag)
        },

        updateCategories({commit}, categories) {
            commit('UPDATE_CATEGORIES', categories)
        },
        replaceCategory({commit}, category) {
            commit('REPLACE_CATEGORY', category)
        },
        setCurrentCategory({commit}, category) {
            commit('SET_CURRENT_CATEGORY', category)
        },
        updateCategoryRoomsData({commit}, params) {
            commit('UPDATE_CATEGORY_ROOMS_DATA', params)
        },
        removeCategoryRoom({commit}, params) {
            commit('REMOVE_CATEGORY_ROOM', params)
        },

        setSearchResult({commit}, results) {
            commit('SET_SEARCH_RESULT', results)
        },
        updateSearchQuery({commit}, query) {
            commit('UPDATE_SEARCH_QUERY', query)
        },
        updateSearchChips({commit}, data) {
            commit('UPDATE_SEARCH_CHIPS', data);
        },
    }
}
