<template>
  <div class="categories-selector-wrapper">
    <div id="categories-selector" class="categories-selector">
      <div
        v-for="category in discoverCategoriesList"
        :key="category.id"
        @click="goToCategory(category)"
      >
        <DiscoverPanelItem :category="category" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DiscoverPanelItem from "./DiscoverPanelItem";

export default {
  name: "DiscoverPanel",

  components: { DiscoverPanelItem },

  data() {
    return {
      blob: process.env.VUE_APP_BASE_BLOB,
      show_category_icon: true,
    }
  },

  computed: {
    ...mapGetters({
      globalFilters: 'globalFilters',
      discoverCategoriesList: 'discoverCategoriesList',
    }),
  },

  methods: {
    goToCategory(category) {
      if (!category.active) {
        return
      }
      this.$router.push({ name: 'discover_category', params: { id: category.id }})
    },
  }
}
</script>

<style lang="scss">
.categories-selector-wrapper {
  margin-bottom: 25px;
  .categories-selector {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -6px;
    padding: 15px 0;
  }
}
</style>
