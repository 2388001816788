<template>
  <div class="room-browser-category">
    <!-- title category and "See all" button -->
    <div class="category-header">
      <h1 class="category-name"
          data-testid="category-name"
          :class="{ 'not-rooms': !hasRooms }"
          @click="goToCategory"
      >
        {{ currentCategory.name }}
      </h1>
      <a v-show="hasRooms"
         data-testid="see-all-button"
         class="link-common"
         @click="goToCategory"
         v-html="$t('message.see_all')"
      />
    </div>
    <!-- presets -->
    <div v-if="!presetEmpty" data-testid="category-preset" class="category-preset">
      <category-preset-item v-for="item in currentCategory.category_preset.list"
                            :key="item.id"
                            :category-preset="item"
                            :selected-category-preset="categoryPresetId"
                            @changePreset="changePreset"
      />
    </div>
    <!-- swiper -->
    <RoomsSwiper v-if="hasRooms" data-testid="room-swiper" :category="category" />
    <RoomPromoteCard v-else-if="isPromotedCategory" />
    <!-- placeholder -->
    <CategoryEmptyPlaceholder v-else data-testid="placeholder" :category-id="category.id" />
    <!-- navigation -->
    <div v-if="hasRooms" data-testid="navigation" class="navigation-container" :class="`category-${currentCategory.id}`">
      <button class="button-prev">
        <img src="@/assets/images/room_browser/btn-more-rooms.svg" alt="icon-prev">
      </button>
      <button class="button-next">
        <img src="@/assets/images/room_browser/btn-more-rooms.svg" alt="icon-next">
      </button>
    </div>
  </div>
</template>

<script>
import RoomPromoteCard from "@/room_browser/components/room/RoomPromoteCard";
import RoomsSwiper from "@/room_browser/components/RoomsSwiper";
import CategoryEmptyPlaceholder from "@/room_browser/components/category/CategoryEmptyPlaceholder";
import CategoryPresetItem from "@/room_browser/components/category/CategoryPresetItem";
import { checkIsPromotedCategory, checkIsRecentlyCategory } from "@/room_browser/services/categories"
import { roomBrowserMixin } from "@/services/mixins";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CategoryCarousel",

  components: {
    CategoryEmptyPlaceholder,
    RoomPromoteCard,
    RoomsSwiper,
    CategoryPresetItem,
  },

  mixins: [ roomBrowserMixin ],

  props: {
    category: {
      type: Object,
      required: true,
      default: () => {}
    }
  },

  data() {
    return {
      currentCategory: {},
      categoryPresetId: null
    };
  },

  computed: {
    ...mapGetters({
      globalFilters: 'globalFilters'
    }),

    hasRooms() {
      return this.currentCategory.rooms.length > 0;
    },
    presetEmpty() {
      return !this.currentCategory.category_preset.list.length
    },
    isRecentlyVisited() {
      return checkIsRecentlyCategory(this.currentCategory) && this.hasRooms
    },
    isPromotedCategory() {
      return checkIsPromotedCategory(this.currentCategory)
    },
  },

  watch: {
    category: {
      handler: function(newValue) {
        this.currentCategory = newValue
        this.categoryPresetId = newValue.category_preset.value
      },
      deep: true
    },
  },

  created() {
    this.currentCategory = this.category ? JSON.parse(JSON.stringify(this.category)) : {}
    this.categoryPresetId = this.currentCategory.category_preset.value
  },

  methods: {
    ...mapActions({
      setCurrentCategory: 'setCurrentCategory',
      replaceCategory: 'replaceCategory',
    }),

    goToCategory() {
      if (!this.hasRooms)  {
        return
      }
      if (this.isHomePage) {
        this.$router.push({ name: 'category', params: { id: this.currentCategory.id }})
      } else {
        this.$router.push({ name: 'discover_category', params: { id: this.currentCategory.id }})
      }
    },

    changePreset(preset) {
      this.categoryPresetId = preset.id
      this.getCategory()
    },

    getCategory() {
      this.$roomBrowserService.getCategory(
          {
            category_id: this.currentCategory.id,
            global_filters: this.globalFilters,
            category_preset: this.categoryPresetId,
            category_filters: [],
            category_sorting: null,
            offset: 0
          },
          (response) => {
            this.currentCategory = response
            this.setCurrentCategory(response)
            this.replaceCategory(response)
          },
          (response) => {
            console.log(response);
          }
      );
    },
  }
}
</script>

<style scoped lang="scss">
.room-browser-category {
  border-top: 1px solid #E5E5E5;
  margin-bottom: 33px;
  position: relative;
  .category-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 16px 0;
    align-items: flex-end;
    .category-name {
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      color: #333333;
      cursor: pointer;
    }
    .not-rooms {
      cursor: default;
    }
    a.link-common {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      cursor: pointer;
    }
  }
  .category-preset {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    gap: 8px;
  }
}
</style>
