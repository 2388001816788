<template>
  <swiper ref="swiper"
          class="category-rooms-swiper"
          :options="swiperOption"
  >
    <swiper-slide v-for="room in previewRooms" :key="room.id">
      <Room :room="room" :is-recently-visited="isRecentlyVisited" />
    </swiper-slide>
    <swiper-slide v-if="isPromotedCategory">
      <RoomPromoteCard />
    </swiper-slide>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import Room from "@/room_browser/components/room/Room";
import RoomPromoteCard from "@/room_browser/components/room/RoomPromoteCard";
import { checkIsPromotedCategory, checkIsRecentlyCategory } from "@/room_browser/services/categories"

export default {
  name: "RoomsSwiper",

  components: {
    Room,
    RoomPromoteCard,
    Swiper,
    SwiperSlide
  },

  props: {
    category: {
      type: Object,
      required: true,
      default: () => {}
    }
  },

  data() {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 12,
        navigation: {
          nextEl: `.navigation-container.category-${this.category.id} .button-next`,
          prevEl: `.navigation-container.category-${this.category.id} .button-prev`
        }
      },

      preview_room_limit: 12,
    };
  },

  computed: {
    totalRooms() {
      return this.category.total_rooms > 0
    },
    previewRooms() {
      return this.category.rooms.slice(0, 12)
    },
    isRecentlyVisited() {
      return checkIsRecentlyCategory(this.category) && this.hasRooms
    },
    isPromotedCategory() {
      return checkIsPromotedCategory(this.category)
    },
  },
}
</script>

<style lang="scss">
.category-rooms-swiper .swiper-slide {
  width: 150px;
}
.category-rooms-swiper .swiper-wrapper {
  height: auto;
}
.navigation-container {
  position: absolute;
  bottom: 90px;
  display: flex;
  width: 100%;
  justify-content: space-between;

  button {
    position: relative;
    opacity: .5;
    padding: 4px 8px;
    cursor: pointer;

    &.button-next {
      right: -30px;
    }
    &.button-prev {
      left: -30px;
      transform: rotate(180deg);
    }
    &:hover {
      opacity: 1;
    }
    &.swiper-button-disabled {
      visibility: hidden;
    }
  }
}
</style>