<template>
  <div id="room-browser-home">
    <div v-if="promos" class="button-banners-container">
      <HomePromoCard v-for="(promo, index) in promos" :key="index" :promo="promo" />
    </div>

    <CategoryCarousel v-for="category in categories" :key="category.type" :category="category" />
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import { roomBrowserMixin } from "@/services/mixins";
import HomePromoCard from "@/room_browser/components/HomePromoCard";
import CategoryCarousel from "@/room_browser/components/category/CategoryCarousel";

export default {
  name: "Home",

  components: {
    HomePromoCard,
    CategoryCarousel
  },

  mixins: [ roomBrowserMixin ],

  data() {
    return {
      promos: [
        {
          callback: () => this.$roomBrowserService.actionRoomStart(),
          phrase: 'message.promo_card.manage_your_room',
          background: "btn-banner-start-room.svg"
        },
        {
          callback: () => this.$roomBrowserService.actionRoomPromote(),
          phrase: 'message.promo_card.promote_room',
          background: "btn-banner-promote.svg"
        },
        {
          callback: () => this.$roomBrowserService.actionUserSubscriptionUpgrade(),
          phrase: 'message.promo_card.upgrade_sub',
          background: "btn-banner-upgrade.svg"
        },
        {
          callback: () => this.$roomBrowserService.actionRoomGiveGift(),
          phrase: 'message.promo_card.give_gift',
          background: "btn-banner-givegift.svg"
        },
        {
          callback: () => this.$roomBrowserService.actionRoomGetCoins(),
          phrase: 'message.promo_card.get_coins',
          background: "btn-banner-buy-coins.svg"
        },
      ],

      categories: []
    }
  },
  computed: {
    ...mapGetters({
      homeCategories: 'homeCategories'
    })
  },
  watch: {
    homeCategories: {
      handler: function(newValue) {
        this.categories = newValue
      },
      deep: true
    }
  },

  created() {
    if (!this.isUserLoggedIn) {
      this.updateLoader(true)
      this.$roomBrowserService.actionUserLogin()
      return
    }

    this.categories = this.homeCategories
  },

  methods: {
    ...mapActions({
      updateLoader: 'updateLoader'
    })
  }
}
</script>

<style lang="scss" scoped>
.button-banners-container {
  margin: 33px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(107px, 1fr));
  gap: 10px;

  @media screen and (min-width: 700px) {
    gap: 20px;
  }

  @media screen and (min-width: 1130px) {
    display: flex;
  }
}

</style>
