import { External as data } from "@/external_data/external";

/** !!!!! DEPRECATED APPROACH, DO NOT USE THIS AS EXAMPLE  !!!!! */

if (process.env.NODE_ENV === 'development') {
  data.csrf = 'asdasdasd';
  data.url  = {
    base: 'https://www-kso.cf-ds.tomsksoft.com/ru/',
    blob: 'https://blob.leetchat.com/',
    profiles: 'https://profiles-kso.cf-ds.tomsksoft.com/',
    static: 'https://static.camfrogcdn.com/',
  };
  data.firebase_config = {};
  data.data = {
    "public_profile": {
      "short_info": {
        "nickname": "kso_bind_room_10",
        "original_nickname": "kso_bind_room_10",
        "avatar_id": 0,
        "alevel": 310,
        "points": 19200,
        "color": "#ff2200",
        "flair_id": '29922',
        "tier_id": 30
      },
      "hopping": {
        "value": "Tolic!"
      },
      "add_info" : {
        "users_online": 152,
        "users_on_cams": 54,
        "followers": 11453,
        "category": "General",
        "language": "Japanese",
        "live_since": "May 12, 2021",
        "topics": {
          "value": [
            "Abandonment and Isolation",
            "Abortion",
            "Abuse",
            "Academics"
          ]
        }
      },
      "gifts": [
        {
          "gift_id":969,
          "image_id":"long/16274",
          "name":"Himself%20only",
          "status":["new","animated"],
          "is_stack":0,
          "xp":4
        },
        {
          "gift_id": 32,
          "image_id": "2671",
          "name": "Golden%20User",
          "status": [
            "new"
          ],
          "is_stack": 0,
          "xp": 1001
        },
        {
          "gift_id": 12,
          "image_id": "2631",
          "name": "Cocktail",
          "status": [
            'reward_category'
          ],
          "is_stack": 0,
          "xp": 10
        },
        {
          "gift_id": 1024,
          "image_id": "20646",
          "name": "USA%20Button",
          "status": [
            "new"
          ],
          "is_stack": 1,
          "xp": 148
        },
        {
          "gift_id": 1087,
          "image_id": "29805",
          "name": "Home%20S-Term%20One%20day%20expire",
          "status": [
            "new"
          ],
          "is_stack": 1,
          "xp": 60
        },
        {
          "gift_id": 1109,
          "image_id": "29888",
          "name": "Mystery%20G2%20grey",
          "status": [
            "new"
          ],
          "is_stack": 0,
          "xp": 3
        },
        {
          "gift_id": 1107,
          "image_id": "29884",
          "name": "mystery%20G1%20Red",
          "status": [
            "new"
          ],
          "is_stack": 1,
          "xp": 25
        }
      ],
      motd: '<b>Write a welcome message to users</b> that will be sent when they enter your room. \n' +
        '\n' +
        '<i>This is a great way to describe</i> your room, make newcomers feel welcome, and present any guidelines/rules of conduct. \n' +
        '\n' +
        'To make the message a disclaimer, simply toggle the “Disclaimer” button below.'
    }
  };
}

export const External = data;
