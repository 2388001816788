import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { messages as rb_messages } from "@/room_browser/i18n";

Vue.use(VueI18n);

const messages = rb_messages;

messages.en.videochat = {
  rb_header_title: 'Camfrog Chat Rooms {rooms_count}',
  rb_subtitle_link: 'Start your own video chat room',
  rb_subtitle: '{0} on Camfrog!'
}

export default new VueI18n({
  locale: 'en',
  messages
});
