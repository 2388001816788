export const messages = {
  "nl": {
    "message": {
      "home": "Home",
      "promo_card": {
        "promote_room": "Promoot een kamer",
        "upgrade_sub": "Upgrade je sub",
        "give_gift": "Geef een cadeau",
        "get_coins": "Krijg Camfrog munten",
        "manage_your_room": "Beheer je kamer"
      },
      "action_menu": {
        "join": "Deelnemen aan kamer",
        "room_info": "Kamer info",
        "gift": "Virtueel geschenk verzenden",
        "report": "Rapporteer deze kamer"
      },
      "search": {
        "search_room": "Zoek kamer",
        "no_results_found": "Geen resultaten gevonden"
      },
      "see_all": "Alles zien",
      "filters": "Filter",
      "sort_by": "Sorteer op",
      "categories": "Categorieën",
      "room_menu": "Kamer menu",
      "go_back": "Ga Terug",
      "refresh_list": "Ververs lijst",
      "password_protected": "Kamer is beveiligd met een wachtwoord",
      "empty_category": {
        "followed": {
          "header": "Geen kamers gevolgd",
          "link_label": "Volg je favoriet {1} om ze aan deze lijst toe te voegen en ze snel te bezoeken.",
          "link_text": "kamers"
        },
        "recent": {
          "header": "Nog geen recente kamers",
          "link_label": "Als u een geschiedenis wilt zien van de kamers die u heeft bezocht, moet u eerst {1}.",
          "link_text": "bezoek enkele kamers"
        },
        "friends": {
          "header": "Geen vrienden in kamers",
          "link_label": "{1} toevoegen aan uw lijst met contactpersonen en zien in welke kamers ze zich bevinden.",
          "link_text": "Voeg meer vrienden toe"
        },
        "default": {
          "header": "Waar zijn de kamers?",
          "link_label": "Het lijkt erop dat er niet genoeg kamers in die taal zijn. Zoek opnieuw onder een andere taal of {1}.",
          "link_text": "begin je eigen kamer"
        }
      },
      "video_chat_room_list": "Video Chat Room Lijst",
      "all_rooms2": "ALLE KAMERS",
      "discover": "ONTDEK",
      "recent2": "RECENTE KAMERS",
      "show_18_chat_room": "Toon 18+ Chat Rooms",
      "hide_18_chat_room": "Verbergen van 18+ Chat kamers",
      "family_filter_off": "Familie Filter Uit",
      "family_filter_on": "Familie Filter Aan",
      "family_filter_18_warning": "18+ waarschuwing",
      "family_filter": "U bent nu van plan de familie filter uit te zetten. Dit geeft u de toegang tot een lijst video chat rooms waar alleen gebruikers boven de 18 toegelaten zijn. Aangezien er alleen gebruikers toegelaten zijn boven de 18 , betekend dit niet dat de room adult content toelaat. Lees AUB de regels voor de room in te gaan.Door de familyfilter te deactiveren verklaard u 18 jaar of ounder te zijn en u accepteerd wat in de {0} beschreven is. Weet u zeker dat u dit wilt doen ?",
      "family_filter_link": "Camfrog Terms of Service",
      "leading": "Leiden",
      "cs": {
        "browser": {
          "search": {
            "desc": "Chat kamers die  overeenkomen met uw zoektermen"
          }
        }
      },
      "client": {
        "roombrowser": {
          "norooms": {
            "text": "Probeer het later opnieuw"
          }
        }
      },
      "see_more": "Zie meer",
      "load_more": "Laad Meer",
      "follow_more_people": "Volg meer mensen",
      "room_name": "Kamer naam: %1%",
      "room_display_name": "Naam Kamer weergeven: %1%",
      "room_category": "Room categorie: %1%",
      "virtual_gift_status": "Virtual gift Status: %1% punten",
      "custom_status": "Aangepaste status: %1%",
      "state": "State: %1%",
      "18_plus_rooms": "18+",
      "deaf_rooms": "Doven",
      "audio_only": "Alleen audio",
      "general": "Algemeen",
      "user_count": "Aantal actieve leden in de chat room",
      "cam_count": "Aantal leden uitzenden hun camera",
      "room_fan_rating": "Sterren op basis van aantal fans van de room",
      "tt_add_room_to_cl": "Deze room toevoegen aan mijn lijst met contactpersonen",
      "tt_remove_room_to_cl": "Verwijder deze kamer uit mijn contact lijst",
      "room_menu_report": "Raporteren deze kamer",
      "no_tags": "Deze kamer heeft nog geen tags",
      "dialog": {
        "yes": "Ja",
        "no": "Nee"
      },
      "clear_category_dialog": {
        "title": "Geschiedenis wissen",
        "content": "Wil je de lijst met rooms die je hebt bezocht wissen?"
      }
    }
  },
  "it": {
    "message": {
      "home": "CASA",
      "promo_card": {
        "promote_room": "Promuovi una stanza",
        "upgrade_sub": "Aggiorna il tuo sub",
        "give_gift": "Dare un regalo",
        "get_coins": "Ottieni Monete Camfrog",
        "manage_your_room": "Gestisci la tua stanza"
      },
      "action_menu": {
        "join": "Entra nella Stanza",
        "room_info": "Informazioni sulla Stanza",
        "gift": "Invia un Regalo Virtuale",
        "report": "Segnala Questa Stanza"
      },
      "search": {
        "search_room": "Cerca Stanza",
        "no_results_found": "Nessun risultato trovato"
      },
      "see_all": "Vedi Tutto",
      "filters": "Filtri",
      "sort_by": "Ordina per",
      "categories": "Categorie",
      "room_menu": "Menu stanze",
      "go_back": "Torna indietro",
      "refresh_list": "Aggiorna la lista",
      "password_protected": "la Stanza è protetta da password",
      "empty_category": {
        "followed": {
          "header": "Nessuna Stanza seguita",
          "link_label": "Segui le tue {1} preferite per aggiungerle a questo elenco e visitarli rapidamente.",
          "link_text": "stanze"
        },
        "recent": {
          "header": "Non ci sono ancora stanze recenti",
          "link_label": "Per vedere una cronologia delle stanze che hai visitato devi prima {1}.",
          "link_text": "visita alcune stanze"
        },
        "friends": {
          "header": "Nessun amico nelle stanze",
          "link_label": "{1} al tuo elenco di contatti e vedere in quali stanze si trovano.",
          "link_text": "Aggiungi più amici"
        },
        "default": {
          "header": "Dove sono le stanze?",
          "link_label": "Sembra che non ci siano abbastanza stanze in quella lingua. Cerca di nuovo in una lingua diversa o {1}.",
          "link_text": "inizia la tua stanza"
        }
      },
      "video_chat_room_list": "Lista delle Video Chat",
      "all_rooms2": "TUTTE LE STANZE",
      "discover": "SCOPRIRE",
      "recent2": "STANZE RECENTI",
      "show_18_chat_room": "Mostra 18+ Chat Rooms",
      "hide_18_chat_room": "Nascondi Chat Rooms +18",
      "family_filter_off": "Filtro Famiglia Off",
      "family_filter_on": "Filtro Famiglia On",
      "family_filter_18_warning": "Attenzione 18+",
      "family_filter": "Stai per disabilitare il filtro famiglia. Questo ti consentirà di vedere la lista delle video chat che consentono l'accesso solo agli utenti maggiorenni . Il fatto che una stanza consenta l'accesso solo ai maggiorenni non significa che consenta contenuti per adulti. Per favore leggi con attenzione le regole della stanza alla quale ti stai unendo. Disattivando il filtro famiglia dichiari di essere maggiorenne e di accettare i {0}. Sei sicuro di volere questo?",
      "family_filter_link": "Termini di Servizio Camfrog",
      "leading": "Primario",
      "cs": {
        "browser": {
          "search": {
            "desc": "Chat room corrispondenti con i tuoi termini di ricerca"
          }
        }
      },
      "client": {
        "roombrowser": {
          "norooms": {
            "text": "Per favore riprova più tardi"
          }
        }
      },
      "see_more": "Più informazioni",
      "load_more": "Carica altre",
      "follow_more_people": "Segui Più Persone",
      "room_name": "Nome della Stanza: %1%",
      "room_display_name": "Nome visualizzato della room: %1%",
      "room_category": "Categoria della Stanza: %1%",
      "virtual_gift_status": "Stato dei Virtual Gift: %1% punti",
      "custom_status": "Stato Personale: %1%",
      "state": "Stato: %1%",
      "18_plus_rooms": "18+",
      "deaf_rooms": "Non udenti",
      "audio_only": "Solo Audio",
      "general": "Generale",
      "user_count": "Numero di persone attive nella stanza",
      "cam_count": "Numero di persone che stanno utilizzando la loro camera",
      "room_fan_rating": "Valutazione basata sul numero di fan in sala",
      "tt_add_room_to_cl": "Aggiungi questa room alla mia lista contatti",
      "tt_remove_room_to_cl": "Rimuovere questa stanza dalla mia lista dei contatti",
      "room_menu_report": "Segnala questa stanza",
      "no_tags": "Questa stanza non ha ancora nessuna etichetta",
      "dialog": {
        "yes": "Si",
        "no": "No"
      },
      "clear_category_dialog": {
        "title": "Cancellare la cronologia",
        "content": "Vuoi cancellare l'elenco delle stanze che hai visitato?"
      }
    }
  },
  "pt-br": {
    "message": {
      "video_chat_room_list": "Lista de Salas de Vídeo Chat",
      "show_18_chat_room": "Mostrar 18+ salas de bate papo",
      "hide_18_chat_room": "Esconder salas 18+",
      "family_filter_off": "Filtro Família Off",
      "family_filter_on": "Filtro Família On",
      "family_filter_18_warning": "Aviso 18+",
      "family_filter": "Você está prestes a desabilitar o filtro familiar. Isto irá permitir que você veja a listagem de salas adultas, para usuários com idade superior a 18 anos. Tenha cuidado e leia atentamente as regras antes de entrar nestas salas. Ao desativar o filtro familiar você confirma que tem mais de 18 anos de idade e concorda com os {0}. Tem certeza que deseja prosseguir?",
      "family_filter_link": "Termos de Serviço do Camfrog",
      "leading": "Liderança",
      "cs": {
        "browser": {
          "search": {
            "desc": "Salas que atendem ao seus critérios"
          }
        }
      },
      "client": {
        "roombrowser": {
          "norooms": {
            "text": "Tente mais tarde"
          }
        }
      },
      "see_more": "Ver Mais",
      "load_more": "Mais",
      "room_name": "Nome da sala: %1%",
      "room_category": "Categoria da sala: %1%",
      "virtual_gift_status": "Virtual Gift Status: %1% points",
      "custom_status": "Estatus do usuário: %1%",
      "state": "Estado: %1%",
      "18_plus_rooms": "18+ Adulto",
      "deaf_rooms": "Deaf",
      "audio_only": "Apenas áudio",
      "general": "Geral",
      "user_count": "Número de usuários ativos na sala de bate-papo",
      "cam_count": "Número de membros  transmitindo a suas câmeras",
      "room_fan_rating": "Estrelar com base no número de fãs da sala",
      "tt_add_room_to_cl": "Adicione esta sala na minha lista de contatos",
      "tt_remove_room_to_cl": "Remover esta sala da minha lista de contactos",
      "room_menu_report": "Denunciar esta Sala",
      "no_tags": "Esta sala ainda não tem nenhum tag"
    }
  },
  "th": {
    "message": {
      "home": "หน้าหลัก",
      "promo_card": {
        "promote_room": "โปรโมทห้อง",
        "upgrade_sub": "อัพเกรดการสมัครของคุณ",
        "give_gift": "ส่งของขวัญ",
        "get_coins": "รับเหรียญ Camfrog",
        "manage_your_room": "จัดการห้องของคุณ"
      },
      "action_menu": {
        "join": "เข้าร่วมห้อง",
        "room_info": "ข้อมูลห้อง",
        "gift": "ส่งของขวัญ",
        "report": "รายงาานห้องนี้"
      },
      "search": {
        "search_room": "ค้นหาห้อง",
        "no_results_found": "ไม่พบ"
      },
      "see_all": "ดูทั้งหมด",
      "filters": "ตัวกรอง",
      "sort_by": "เรียงโดย",
      "categories": "หมวดหมู่",
      "room_menu": "รายชื่อห้อง",
      "go_back": "กลับไป",
      "refresh_list": "รีเฟรชรายการ",
      "password_protected": "ป้องกันด้วยรหัสผ่าน",
      "empty_category": {
        "followed": {
          "header": "ไม่ได้ติดตามห้องใดๆ",
          "link_label": "ติดตาม {1} ที่คุณชื่นชอบและเพิ่มไปยังรายการเพื่อเข้าร่วมได้อย่างรวดเร็ว",
          "link_text": "ห้อง"
        },
        "recent": {
          "header": "ยังไม่มีห้องล่าสุด",
          "link_label": "หากต้องการดูประวัติห้องที่คุณเคยเยี่ยมชม คุณต้องเข้าร่วม {1} ก่อน",
          "link_text": "เยี่ยมชมห้อง"
        },
        "friends": {
          "header": "ไม่มีเพื่อนในห้อง",
          "link_label": "{1} ในรายชื่อผู้ติดต่อของคุณและดูว่าพวกเขาอยู่ในห้องไหน",
          "link_text": "เพิ่มเพื่อนคนอื่น"
        },
        "default": {
          "header": "อยู่ห้องไหน?",
          "link_label": "ดูเหมือนว่ามีห้องไม่เพียงพอในภาษานั้น ค้นหาอีกครั้งด้วยภาษาอื่นหรือ {1}",
          "link_text": "เริ่มเป็นเจ้าของห้อง"
        }
      },
      "video_chat_room_list": "รายชื่อห้องสนทนา",
      "all_rooms2": "ห้องทั้งหมด",
      "discover": "ค้นพบ",
      "recent2": "ห้องล่าสุด",
      "show_18_chat_room": "แสดงห้องสนทนา 18+",
      "hide_18_chat_room": "ซ่อนห้องสนทนา 18+",
      "family_filter_off": "ปิดโหมดครอบครัว",
      "family_filter_on": "เปิดโหมดครอบครัว",
      "family_filter_18_warning": "คำเตือน 18+",
      "family_filter": "คุณกำลังปิดโหมดการใช้งานระบบควบคุมของผู้ปกครองที่ให้คำปรึกษาดูแล และแนะนำเรื่องพฤติกรรม ผู้ที่ใช้ระบบนี้ต้องมีอายุไม่ต่ำกว่า 18 ปีขึ้นไป การเข้าสนทนาในกลุ่ม 18 ปีขึ้นไป ซึ่งไม่ได้หมายความว่าห้องสนทนาจะสื่อถึงสิ่งลามกอนาจาร กรุณาศึกษารายละเอียดและกฏข้อบังคับต่างๆ ให้ละเอียด เลือกปิดการควบคุมการเข้าห้องสนทนาโดยผู้ปกครอง กดเลือก ตกลง และทำตามข้อบังคับของ {0} คุณต้องการปิดโหมดผู้ปกครองดูแลการสนทนาใช่หรือไม่?",
      "family_filter_link": "ระเบียบการให้บริการ",
      "leading": "นำหน้า",
      "cs": {
        "browser": {
          "search": {
            "desc": "ห้องสนทนาที่ตรงกับคำค้นหาของคุณมากที่สุด"
          }
        }
      },
      "client": {
        "roombrowser": {
          "norooms": {
            "text": "กรุณาลองอีกครั้งภายหลัง"
          }
        }
      },
      "see_more": "อื่นๆ อีกมากมาย",
      "load_more": "โหลดเพิ่มเติม",
      "follow_more_people": "ติดตามคนอื่นๆ",
      "room_name": "ชื่อห้อง: %1%",
      "room_display_name": "ชื่อห้องที่แสดง: %1%",
      "room_category": "หมวดหมู่ห้องสนทนา: %1%",
      "virtual_gift_status": "คะแนนของขวัญ: %1% points",
      "custom_status": "กำหนดสถานะ: %1%",
      "state": "ประเทศ: %1%",
      "18_plus_rooms": "18+",
      "deaf_rooms": "เฉพาะผู้บกพร่องทางโสตถ์",
      "audio_only": " เสียงเท่านั้น",
      "general": "ทั่วไป",
      "user_count": "จำนวนสมาชิกในห้องสนทนา",
      "cam_count": "จำนวนสมาชิกที่เปิดกล้องของพวกเขา",
      "room_fan_rating": "how are you",
      "tt_add_room_to_cl": "เพิ่มห้องไปในยังรายชื่อผู้ติดต่อ",
      "tt_remove_room_to_cl": "ลบห้องนี้ออกจากรายการ",
      "room_menu_report": "แจ้งการกระทำความผิดห้องนี้",
      "no_tags": "ยังไม่พบคำที่เกี่ยวข้องของห้องนี้",
      "dialog": {
        "yes": "ตกลง",
        "no": "ไม่"
      },
      "clear_category_dialog": {
        "title": "ล้างประวัติ",
        "content": "คุณต้องการล้างรายการห้องที่คุณเคยเยี่ยมชมหรือไม่?"
      }
    }
  },
  "ru": {
    "message": {
      "home": "ГЛАВНАЯ",
      "promo_card": {
        "promote_room": "Продвинуть чат",
        "upgrade_sub": "Получить апгрейд",
        "give_gift": "Отправить подарок",
        "get_coins": "Получить Монеты",
        "manage_your_room": "Настройки чата"
      },
      "action_menu": {
        "join": "Зайти в Чат",
        "room_info": "Детали Чата",
        "gift": "Отправить Подарок",
        "report": "Пожаловаться на Чат"
      },
      "search": {
        "search_room": "Поиск по Чату",
        "no_results_found": "Ничего не найдено"
      },
      "see_all": "Смотреть Всё",
      "filters": "Фильтр",
      "sort_by": "Сортировка",
      "categories": "Категории",
      "room_menu": "Меню чата",
      "go_back": "Назад",
      "refresh_list": "Обновить список",
      "password_protected": "Чат защищен паролем",
      "empty_category": {
        "followed": {
          "header": "Вы не состоите в чатах",
          "link_label": "Вступите в любимые {1}, чтобы они отобразились в этом списке для быстрого доступа.",
          "link_text": "чаты"
        },
        "recent": {
          "header": "У вас нет недавних чатов",
          "link_label": "Для того, чтобы появилась история посещенных чатов, вам сперва необходимо {1}.",
          "link_text": "посетить чаты"
        },
        "friends": {
          "header": "Нет друзей в чатах",
          "link_label": "{1}, чтобы увидеть в каких чатах они сейчас находятся.",
          "link_text": "Добавьте друзей"
        },
        "default": {
          "header": "Где Чаты?",
          "link_label": "Похоже, чатов на данном языке крайне мало. Попробуйте найти чаты на другом языке или {1}.",
          "link_text": "начните свой чат"
        }
      },
      "video_chat_room_list": "Каталог чатов",
      "all_rooms2": "ВСЕ ЧАТЫ",
      "discover": "ЕЩЕ ЧАТЫ",
      "recent2": "НЕДАВНИЕ",
      "show_18_chat_room": "Показать Чаты 18+",
      "hide_18_chat_room": "Скрыть Чаты 18+",
      "family_filter_off": "Откл возрастные ограничения",
      "family_filter_on": "Вкл возрастные ограничения",
      "family_filter_18_warning": "Внимание! 18+",
      "family_filter": "Вы собираетесь отключить возрастные ограничения. Это действие выведет в список чаты, разрешенные пользователям 18 лет и старше. Доступ в чаты пользователям от 18 лет не означает разрешение на непристойное содержимое. Внимательно читайте правила чата перед входом в него. Выключая возрастные ограничения, вы подтверждаете, что вам 18 лет или более, а также соглашаетесь с {0}. Продолжить?",
      "family_filter_link": "условиями пользования сервисом Camfrog",
      "leading": "Топ",
      "cs": {
        "browser": {
          "search": {
            "desc": "Чаты, соответствующие параметрам поиска"
          }
        }
      },
      "client": {
        "roombrowser": {
          "norooms": {
            "text": "Попробуйте позднее"
          }
        }
      },
      "see_more": "Весь список",
      "load_more": "Загрузить еще",
      "follow_more_people": "Найти больше людей",
      "room_name": "Имя чат комнаты: %1%",
      "room_display_name": "Отображаемый ник чата: %1%",
      "room_category": "Категория чат комнаты: %1%",
      "virtual_gift_status": "Рейтинг подарков: %1% очков",
      "custom_status": "Персональный статус: %1%",
      "state": "Состояние: %1%",
      "18_plus_rooms": "18+",
      "deaf_rooms": "Язык жестов",
      "audio_only": "Только звук",
      "general": "Общие",
      "user_count": "Число активных пользователей чат комнаты",
      "cam_count": "Число пользователей с камерами",
      "room_fan_rating": "Рейтинг, основанный на количестве поклонников чата",
      "tt_add_room_to_cl": "Добавить чат кoмнату в список контактов",
      "tt_remove_room_to_cl": "Удалить этот чат из списка контактов",
      "room_menu_report": "Сообщить о нарушениях в чате",
      "no_tags": "Чат комнаты на данный момент не имеют тегов",
      "dialog": {
        "yes": "Да",
        "no": "Нет"
      },
      "clear_category_dialog": {
        "title": "Очистить историю",
        "content": "Хотите очистить список посещенных вами чатов?"
      }
    }
  },
  "pl": {
    "message": {
      "video_chat_room_list": "Wideo Czat Listy Pokoju",
      "show_18_chat_room": "Pokaż 18+ Czatu Pokoju",
      "hide_18_chat_room": "Ukryj 18+ Czat Pokoju",
      "family_filter_off": "Wyłącz filtr rodzinny",
      "family_filter_on": "Włącz filtr rodzinny",
      "family_filter_18_warning": "Uwaga 18+ (dla dorosłych)",
      "family_filter": "Masz zamiar wyłączyć filtr rodzinny. Pozwoli to, aby wyświetlić listę z video czatów, które zezwalają użytkowników, którzy mają 18 lat. Tylko dlatego, że tylko pokój pozwala użytkownikom 18 lat nie oznacza, pomieszczenia pozwala treści dla dorosłych. Proszę uważnie przeczytać regulamin pokoju po wlaczeniu. {0} Wyłączając filtr rodzinny można potwierdzić, że jesteś w wieku 18 lat lub starsze i zgadzasz się na warunki. Czy na pewno chcesz to zrobić?",
      "family_filter_link": "Regulamin Camfrogu",
      "cs": {
        "browser": {
          "search": {
            "desc": "Czaty pasuje do podanych nazw"
          }
        }
      },
      "client": {
        "browser": {
          "search": {
            "empty_result": "Niestety, nie znaleźliśmy pokój, który pasował wyszukiwane nazw."
          }
        }
      },
      "see_more": "Pokaż więcej",
      "load_more": "Więcej odczyt",
      "room_name": "Nazwa Pokój: %1%",
      "room_category": "nKatalog Pokój: %1%",
      "virtual_gift_status": "nWirtualne stanu Prezent: %1% punktów",
      "custom_status": "nOpis: %1%",
      "state": "nStan: %1%",
      "18_plus_rooms": "18+",
      "deaf_rooms": "Głuchy",
      "audio_only": "Tylko audio",
      "general": "Ogólne",
      "user_count": "Ilość aktywny członków w czacie pokoju",
      "cam_count": "Liczba członków nadawania ich kamery",
      "room_fan_rating": "Ilość wg liczby oceny i fanów pokoju",
      "tt_add_room_to_cl": "Dodaj ten pokój do listy kontaktów",
      "tt_remove_room_to_cl": "Kasuje to pokój z moim listu kontakt",
      "room_menu_report": "Zgłoś pokój",
      "no_tags": "W pokoju nie ma jeszcze żadnych tagów"
    }
  },
  "zh-t": {
    "message": {
      "home": "家",
      "promo_card": {
        "promote_room": "推廣房間",
        "upgrade_sub": "升級你的訂閱",
        "give_gift": "贈送禮物",
        "get_coins": "獲取Camfrog金幣",
        "manage_your_room": "管理您的房間"
      },
      "action_menu": {
        "join": "加入房間",
        "room_info": "房間信息",
        "gift": "發送虛擬禮物",
        "report": "舉報此房間"
      },
      "search": {
        "search_room": "搜索房間",
        "no_results_found": "未找到結果"
      },
      "see_all": "看到所有",
      "filters": "篩選",
      "sort_by": "排序方式",
      "categories": "類別",
      "room_menu": "房間菜單列表",
      "go_back": "返回",
      "refresh_list": "刷新列表",
      "password_protected": "房間有密碼保護",
      "empty_category": {
        "followed": {
          "header": "沒有房間跟隨",
          "link_label": "关注您最喜欢的{1}，将它们添加到此列表并快速访问它们.",
          "link_text": "房間"
        },
        "recent": {
          "header": "還沒有最近的房間",
          "link_label": "要查看您訪問過的房間的歷史記錄，您必須先{1}.",
          "link_text": "參觀一些房間"
        },
        "friends": {
          "header": "房間裡沒有朋友",
          "link_label": "{1}到您的聯繫人列表，查看他們所在的房間.",
          "link_text": "添加更多朋友"
        },
        "default": {
          "header": "房間在哪裡?",
          "link_label": "看來沒有足夠的能力講那種語言了.在不同的語言列表或{1}下再次搜索.",
          "link_text": "創建自己的房間"
        }
      },
      "video_chat_room_list": "視訊聊天室清單",
      "all_rooms2": "購買房間",
      "discover": "發現",
      "recent2": "最近的房間",
      "show_18_chat_room": "顯示18+聊天室",
      "hide_18_chat_room": "隱藏18+聊天室",
      "family_filter_off": "關閉兒童保護篩檢程式",
      "family_filter_on": "開啟兒童保護篩檢程式",
      "family_filter_18_warning": "18+警告",
      "family_filter": "你正要關閉家庭防護功能.這將會讓你只看到18歲或以上的視訊聊天室清單.雖並不表示含有成人內容，但因為房間允許18歲或以上的人進入，因此進入的時候請詳細閱讀房間規則.關掉家庭防護功能之前請確定你是18歲或以上的使用者以及您同意{0}，你確定你要這樣做嗎?",
      "family_filter_link": "服務條規",
      "leading": "主要的",
      "cs": {
        "browser": {
          "search": {
            "desc": "與您搜尋字詞相匹配的聊天室"
          }
        }
      },
      "client": {
        "roombrowser": {
          "norooms": {
            "text": "請稍後重試"
          }
        }
      },
      "see_more": "更多詳情",
      "load_more": "更多",
      "follow_more_people": "關注更多的人",
      "room_name": "聊天室名稱：%1%",
      "room_display_name": "房間顯示名稱: %1%",
      "room_category": "聊天室類別：%1%",
      "virtual_gift_status": "虛擬禮物的狀態：%1%積分",
      "custom_status": "自訂狀態：%1%",
      "state": "狀態：%1%",
      "18_plus_rooms": "18+",
      "deaf_rooms": "聾啞房",
      "audio_only": "只有聲音",
      "general": "一般",
      "user_count": "聊天室中活躍會員的人數",
      "cam_count": "正在透過網路攝影機進行播放的會員人數",
      "room_fan_rating": "根據聊天室追隨者的數量來評星級",
      "tt_add_room_to_cl": "將這一聊天室添加到我的聯絡人清單",
      "tt_remove_room_to_cl": "從我的連絡人清單中移除這一聊天室",
      "room_menu_report": "舉報這一聊天室",
      "no_tags": "這間聊天室還沒有添加任何標籤",
      "dialog": {
        "yes": "是的",
        "no": "不"
      },
      "clear_category_dialog": {
        "title": "清楚歷史記錄",
        "content": "你想清除您訪問過的房間列表嗎?"
      }
    }
  },
  "en-uk": {
    "message": {
      "video_chat_room_list": "Video Chat Room List",
      "show_18_chat_room": "Show 18+ Chat Rooms",
      "hide_18_chat_room": "Hide 18+ Chat Rooms",
      "family_filter_off": "Family Filter Off",
      "family_filter_on": "Family Filter On",
      "family_filter_18_warning": "18+ Warning",
      "family_filter": "You are about to disable the family filter. This will allow you to view a list of video chat rooms that only allow users that are 18 or over. Just because a room only allows users 18 or over, does not mean the room allows adult content. Please read the rules of the room carefully before joining. By turning off the family filter you confirm you are 18 years of age or older and you agree to the {0}. Are you sure you want to do this?",
      "family_filter_link": "Camfrog Terms of Service",
      "cs": {
        "browser": {
          "search": {
            "desc": "Chat rooms matching your search terms"
          }
        }
      },
      "client": {
        "browser": {
          "search": {
            "empty_result": "Sorry, we didn't find a room that matched your search terms."
          }
        }
      },
      "see_more": "See more",
      "load_more": "Load More",
      "room_name": "Room Name: %1%",
      "room_category": "nRoom Category: %1%",
      "virtual_gift_status": "nVirtual Gift Status: %1% points",
      "custom_status": "nCustom Status: %1%",
      "state": "nState: %1%",
      "18_plus_rooms": "18+",
      "deaf_rooms": "Deaf",
      "audio_only": "Audio only",
      "general": "General",
      "user_count": "Number of active members in the chat room",
      "cam_count": "Number of members broadcasting their camera",
      "room_fan_rating": "Star rating based on number of fans of the room",
      "tt_add_room_to_cl": "Add this room to my contact list",
      "room_menu_report": "Report this room",
      "no_tags": "This room does not have any tags yet"
    }
  },
  "tr": {
    "message": {
      "home": "EV",
      "promo_card": {
        "promote_room": "Bir odayı yükseltmek",
        "upgrade_sub": "Aboneliğinizi yükseltin",
        "give_gift": "Hediye ver",
        "get_coins": "Camfrog Jetonları alın",
        "manage_your_room": "Odanızı yönetin"
      },
      "action_menu": {
        "join": "Odaya Katıl",
        "room_info": "Oda Bilgileri",
        "gift": "Sanal Hediye Gönder",
        "report": "Bu Odayı Bildirin"
      },
      "search": {
        "search_room": "Oda Ara",
        "no_results_found": "Sonuç bulunamadı"
      },
      "see_all": "Hepsini Gör",
      "filters": "Filtre",
      "sort_by": "Göre sırala",
      "categories": "Kategoriler",
      "room_menu": "Oda menüsü",
      "go_back": "Geri git",
      "refresh_list": "Listeyi yenile",
      "password_protected": "Oda şifre korumalıdır",
      "empty_category": {
        "followed": {
          "header": "Takip edilen oda yok",
          "link_label": "Senin favori {1} bu listeye eklemek için takip edin ve hızlıca ziyaret edin.",
          "link_text": "odalar"
        },
        "recent": {
          "header": "Henüz yeni oda yok",
          "link_label": "Ziyaret ettiğiniz odaların geçmişini görmek için önce {1} etmelisiniz.",
          "link_text": "bazı odaları ziyaret et"
        },
        "friends": {
          "header": "Odalarda arkadaş yok",
          "link_label": "Kişi listenize {1} ve hangi odalarda olduklarını görün.",
          "link_text": "Daha fazla arkadaş ekle"
        },
        "default": {
          "header": "Odalar nerede?",
          "link_label": "O dilde yeterli oda yok gibi görünüyor. Farklı bir dil altında tekrar arama yapın veya {1}.",
          "link_text": "kendi odanı başlat"
        }
      },
      "video_chat_room_list": "Görüntülü Sohbet Odası Listesi",
      "all_rooms2": "BÜTÜN ODALAR",
      "discover": "KEŞFET",
      "recent2": "SON ODALAR",
      "show_18_chat_room": "18+ odaları göster",
      "hide_18_chat_room": "18+ odalarını gizle",
      "family_filter_off": "Aile flitresi kapalı",
      "family_filter_on": "Aile flitresi açık",
      "family_filter_18_warning": "Uyarı 18+",
      "family_filter": "Aile filtresini kapatmak üzeresiniz. Bu 18 yaş veya üzerindeki kullanıcılara izin veren görüntülü sohbet odalarının listesini görmenizi sağlayacaktır. Bir odanın 18 yaş veya üzerindeki kullanıcılara ayrılması yetişkinler için içeriğe izin verildiğini anlamına gelmez. Odaya girerken lütfen oda kurallarını dikkatle okuyunuz. Aile filtresini kapatarak 18 yaş veya üzerinde olduğunuzu ve {0} kabul ettiğinizi onaylıyorsunuz. Bunu yapmak istediğinizden emin misiniz?",
      "family_filter_link": "Camfrog Kullanım Şartlarını",
      "leading": "Ileri gelen",
      "cs": {
        "browser": {
          "search": {
            "desc": "Arama kriterlerinize uyan odalar"
          }
        }
      },
      "client": {
        "roombrowser": {
          "norooms": {
            "text": "Lütfen sonra tekrar dene"
          }
        }
      },
      "see_more": "daha fazla",
      "load_more": "Daha Fazla Yükle",
      "follow_more_people": "Daha Fazla Kişiyi Takip Et",
      "room_name": "Oda Ismi: %1%",
      "room_display_name": "Oda Görünen Adı: %1%",
      "room_category": "Oda Sekenekleri: %1%",
      "virtual_gift_status": "Sanal Hediye Durumu: %1% puan",
      "custom_status": "Durum: %1%",
      "state": "Genel durum: %1%",
      "18_plus_rooms": "18+",
      "deaf_rooms": "İşitme Engelliler",
      "audio_only": "Sadece ses",
      "general": "Genel",
      "user_count": "Oda icerisende en fazla aktif olan uyelerin sayisi",
      "cam_count": "Oda iceresinde kamerasi acik olan kullanici sayisi",
      "room_fan_rating": "Star puanlamasi odanin takipci sayisina gore uygulanmaktadir",
      "tt_add_room_to_cl": "Odayı kişi listeme ekle",
      "tt_remove_room_to_cl": "Bu sohbet odasini listemden cikart",
      "room_menu_report": "Bu odayı ihbar et",
      "no_tags": "Bu oda henüz bir etikete sahip değil",
      "dialog": {
        "yes": "Evet",
        "no": "Hayır"
      },
      "clear_category_dialog": {
        "title": "Geçmişi Sil",
        "content": "Ziyaret ettiğin odaların listesini temizlemek istiyor musun?"
      }
    }
  },
  "de": {
    "message": {
      "home": "START",
      "promo_card": {
        "promote_room": "Werbe für einen Raum",
        "upgrade_sub": "Verbessere dein Abonnement",
        "give_gift": "Verschenke ein Geschenk",
        "get_coins": "Hole Camfrog Münzen",
        "manage_your_room": "Verwalte deinen Raum"
      },
      "action_menu": {
        "join": "Betrete Raum",
        "room_info": "Raum Info",
        "gift": "Sende ein virtuelles Geschenk",
        "report": "Melde diesen Raum"
      },
      "search": {
        "search_room": "Suche Raum",
        "no_results_found": "Keine Treffer gefunden"
      },
      "see_all": "Alles sehen",
      "filters": "Filter",
      "sort_by": "Sortieren nach",
      "categories": "Kategorien",
      "room_menu": "Raum Menü",
      "go_back": "Gehe zurück",
      "refresh_list": "Liste aktualisieren",
      "password_protected": "Raum ist passwortgeschützt",
      "empty_category": {
        "followed": {
          "header": "Keine Räume gefolgt",
          "link_label": "Folge deiner favorisierten {1} um sie in dieser Liste hinzuzufügen und schnell zu besuchen.",
          "link_text": "Räume"
        },
        "recent": {
          "header": "Noch keine neuen Räume",
          "link_label": "Um einen Verlauf der von Dir besuchten Räume anzuzeigen, müsst du zuerst {1}.",
          "link_text": "einige Räume besuchen"
        },
        "friends": {
          "header": "Keine Freunde in Räumen",
          "link_label": "{1} deiner Kontaktliste und sieh, in welchen Räumen sie sich befinden.",
          "link_text": "Füge weitere Freunde hinzu"
        },
        "default": {
          "header": "Wo sind Räume?",
          "link_label": "Anscheinend gibt es nicht genügend Räume in dieser Sprache. Suche erneut unter einer anderen Sprache oder {1}.",
          "link_text": "eröffne deinen eigenen Raum"
        }
      },
      "video_chat_room_list": "Video Chat Raumliste",
      "all_rooms2": "ALLE RÄUME",
      "discover": "ENTDECKEN",
      "recent2": "LETZTE RÄUME",
      "show_18_chat_room": "Zeige 18+ Räume",
      "hide_18_chat_room": "Verberge 18+ Räume",
      "family_filter_off": "Jugendschutzfilter Aus",
      "family_filter_on": "Jugendschutzfilter Ein",
      "family_filter_18_warning": "18+ Warnung",
      "family_filter": "Du bist dabei den Jugendschutzfilter abzuschalten. Dies ermöglicht Dir Raumlisten einzusehen die NUR für Erwachsene erlaubt sind. Ein Raum nur für Erwachsene heisst nicht, dass dort alles erlaubt ist, lies Dir deshalb bitte immer vor Betreten die Raumregeln genau durch. Mit dem Abschalten des Jugendschutzfilters bestätigst Du das 18. Lebensjahr vollendet zu haben und akzeptierst die {0}. Bist Du sicher dies tun zu wollen?",
      "family_filter_link" : "Camfrog Nutzungsbedingungen TOS",
      "leading": "Führend",
      "cs": {
        "browser": {
          "search": {
            "desc": "Räume, die Deinen Suchkriterien entsprechen"
          }
        }
      },
      "client": {
        "roombrowser": {
          "norooms": {
            "text": "Bitte versuche es später noch einmal"
          }
        }
      },
      "see_more": "Mehr anzeigen",
      "load_more": "Mehr anzeigen",
      "follow_more_people": "Folge Mehr Leuten",
      "room_name": "Raumname: %1%",
      "room_display_name": "Raum Anzeige Name: %1%",
      "room_category": "Raum Kategorie: %1%",
      "virtual_gift_status": "Virtueller Geschenkstatus: %1% Punkte",
      "custom_status": "Custom Status: %1%",
      "state": "Status: %1%",
      "18_plus_rooms": "18+",
      "deaf_rooms": "Gehörlos",
      "audio_only": "nur Audio",
      "general": "Generell",
      "user_count": "Anzahl der aktiven Mitglieder im Chatraum",
      "cam_count": "Anzahl der übertragenden Mitglieder",
      "room_fan_rating": "Sternebewertung basierend auf der Anzahl der Fans des Raumes",
      "tt_add_room_to_cl": "Diesen Raum meinen Kontakten hinzufügen",
      "tt_remove_room_to_cl": "Entferne diesen Raum von meiner Kontaktliste",
      "room_menu_report": "Diesen Raum melden",
      "no_tags": "Dieser Raum hat noch keine Tags.",
      "dialog": {
        "yes": "Ja",
        "no": "Nein"
      },
      "clear_category_dialog": {
        "title": "Leere Verlauf",
        "content": "Möchten Du die Liste der von Dir besuchten Räume löschen?"
      }
    }
  },
  "ar": {
    "message": {
      "home": "الرئيسية",
      "promo_card": {
        "promote_room": "روج لغرفة",
        "upgrade_sub": "ترقية اشتراكك",
        "give_gift": "ارسال هدية",
        "get_coins": "الحصول على عملات كام فروك",
        "manage_your_room": "ادارة غرفتك"
      },
      "action_menu": {
        "join": "انضم الى الغرفة",
        "room_info": "معلومات الغرفة",
        "gift": "ارسال هدية افتراضية",
        "report": "أبلغ عن هذه الغرفة"
      },
      "search": {
        "search_room": "غرفة البحث",
        "no_results_found": "لم يتم العثور على نتائج"
      },
      "see_all": "اظهار الكل",
      "filters": "فلتر",
      "sort_by": "صنف حسب",
      "categories": "التصنيفات",
      "room_menu": "قائمة الغرفة",
      "go_back": "الرجوع",
      "refresh_list": "تحديث القائمة",
      "password_protected": "الغرفة محمية بكلمة مرور",
      "empty_category": {
        "followed": {
          "header": "لم يتم متابعة أي غرف",
          "link_label": "تابع {1} المفضلة لديك لإضافتها إلى هذه القائمة وزيارتها بسرعة.",
          "link_text": "الغرف"
        },
        "recent": {
          "header": "لا توجد غرف حديثة حتى الآن",
          "link_label": "لمشاهدة سجل الغرف التي قمت بزيارتها ، يجب عليك {1} أولاً.",
          "link_text": "قم بزيارة بعض الغرف"
        },
        "friends": {
          "header": "لا يوجد أصدقاء في الغرف",
          "link_label": "{1} إلى قائمة جهات الاتصال الخاصة بك واطلع على الغرف التي يوجدون فيها.",
          "link_text": "إضافة المزيد من الأصدقاء"
        },
        "default": {
          "header": "اين الغرف؟",
          "link_label": "يبدو أنه لا توجد غرف كافية بهذه اللغة. ابحث مرة أخرى بلغة مختلفة أو{1}.",
          "link_text": "ابدأ غرفتك الخاصة"
        }
      },
      "video_chat_room_list": "قائمة الغرف",
      "all_rooms2": "جميع الغرف",
      "discover": "اكتشف",
      "recent2": "الغرف الأخيرة",
      "show_18_chat_room": "عرض غرف دردشة +18",
      "hide_18_chat_room": "اخفاء غرف دردشة +18",
      "family_filter_off": "التصفية العائلية  مغلقة",
      "family_filter_on": "التصفية العائلية مفعلة",
      "family_filter_18_warning": "تحذير +18",
      "family_filter": "انت على وشك تعطيل فلتر الاسرة. هذا سوف يسمح لك بعرض قائمة من غرف الدردشه المرئيه تسمح فقط للمستخدمين البالغين من العمر 18 سنة او اكثر . وليس معنى ان هذة الغرفه تسمح بدخول البالغين 18 سنة او اكثر انها تحتوى على مواد للبالغين. برجاء قراءة قواعد الغرفة بعنايه عند دخولك . عند قيامك بتعطيل خاصيه فلتر الاسرة فانك بذلك تؤكد على انك تبلغ من العمر 18 سنة او اكثر وانك توافق على شروط الخدمة فى الكامفروج هل تريد القيام بذلك {0}",
      "family_filter_link": "شروط خدمة الكامفروج",
      "leading": "قيادة",
      "cs": {
        "browser": {
          "search": {
            "desc": "غرف الدردشة مطابقة شروط البحث الخاصة بك"
          }
        }
      },
      "client": {
        "roombrowser": {
          "norooms": {
            "text": "الرجاء معاودة المحاولة في وقت لاحق"
          }
        }
      },
      "see_more": "شاهد المزيد",
      "load_more": "تحميل المزيد",
      "follow_more_people": "متابعة المزيد من الاشخاص",
      "room_name": "%1% اسم الغرفة",
      "room_display_name": "%1% اسم عرض الغرفة:",
      "room_category": " %1% اغرفة الفئة",
      "virtual_gift_status": "فيرجول هدية الحالة: %1% نقطة",
      "custom_status": "مخصص الحالة%1%",
      "state": " %1% دولة",
      "18_plus_rooms": "18+",
      "deaf_rooms": "الصم",
      "audio_only": "الصوت فقط",
      "general": "العام",
      "user_count": "عدد من أعضاء فاعلين في غرفة دردشة",
      "cam_count": "عدد   الأعضاء الذين يبثون   الكاميرا الخاصة بهم",
      "room_fan_rating": "تصنيف التقييم يعتمد على عدد منالمعجبين في الغرفة",
      "tt_add_room_to_cl": "إضف هذه الغرفة إلى قائمة الاتصال الخاصة بي",
      "tt_remove_room_to_cl": "إزالة هذه الغرفة من قائمة الاتصال الخاصة بي",
      "room_menu_report": "بلغ عن هذه الغرفة",
      "no_tags": "هذه الغرفة لا تملك  أية علامات بعد",
      "dialog": {
        "yes": "نعم",
        "no": "لا"
      },
      "clear_category_dialog": {
        "title": "مسح التاريخ",
        "content": "هل تريد مسح قائمة الغرف التي قمت بزيارتها؟"
      }
    }
  },
  "no": {
    "message": {
      "video_chat_room_list": "Video chatterom liste",
      "family_filter": "Du er om til å deaktivere den familie filteret. Denne skal tilatte deg til å vise en liste av video chatteromene som bare tillate brukere som er 18 eller eldre. Bare fordi et rom bare tillater bruker 18 eller eldre betyr ikke det at romet tilatter bare voksent innhold. Vennligst les nøye de reglene i romet når du kommer inn. Ved å slå av den familie filteret du har bekreftet du er 18 år av alder eller eldre og du er enig til den {0}. Er du sikkert du vil gjøre dette?",
      "family_filter_link": "Camfrog Vilkår for bruk",
      "load_more": "Last mer",
      "18_plus_rooms": "18+",
      "deaf_rooms": "Døve",
      "audio_only": "Lyd bare",
      "general": "Generelt"
    }
  },
  "id": {
    "message": {
      "home": "Rumah",
      "promo_card": {
        "promote_room": "Ruang promosi",
        "upgrade_sub": "Tingkatkan sub",
        "give_gift": "Berikan hadiah",
        "get_coins": "Dapatkan koin camfrog",
        "manage_your_room": "Kelola ruangmu"
      },
      "action_menu": {
        "join": "Gabung Ruang",
        "room_info": "Informasi Ruang",
        "gift": "Kirim Hadiah Virtual",
        "report": "Laporkan Ruang Ini"
      },
      "search": {
        "search_room": "Ruang Pencarian",
        "no_results_found": "Tidak ada hasil yang ditemukan"
      },
      "see_all": "Lihat Semua",
      "filters": "Saring",
      "sort_by": "Sortir dengan",
      "categories": "Kategori",
      "room_menu": "Menu Ruang",
      "go_back": "Kembali",
      "refresh_list": "Daftar segar",
      "password_protected": "Ruang dilindungi kata sandi",
      "empty_category": {
        "followed": {
          "header": "Tidak ada Ruang yang diikuti",
          "link_label": "Ikuti {1} favorit Anda untuk menambahkannya ke daftar ini dan kunjungi mereka dengan cepat",
          "link_text": "ruang"
        },
        "recent": {
          "header": "Belum ada kamar terbaru",
          "link_label": "Untuk melihat riwayat ruang yang telah Anda kunjungi, Anda harus {1} terlebih dahulu.",
          "link_text": "mengunjungi beberapa ruang"
        },
        "friends": {
          "header": "Tidak ada teman dalam ruang",
          "link_label": "{1} ke daftar kontak Anda dan lihat di ruang mana mereka berada.",
          "link_text": "Tambahkan lebih banyak teman"
        },
        "default": {
          "header": "Dimana ruang?",
          "link_label": "Sepertinya tidak ada cukup ruang dalam bahasa itu. Telusuri lagi dalam bahasa lain atau {1}.",
          "link_text": "mulai kamarmu sendiri"
        }
      },
      "video_chat_room_list": "Daftar bilik percakapan",
      "all_rooms2": "SEMUA RUANG",
      "discover": "MENEMUKAN",
      "recent2": "RUANG TERBARU",
      "show_18_chat_room": "Tampilkan Bilik Percakapan 18+",
      "hide_18_chat_room": "Sembunyikan Bilik Percakapan 18+",
      "family_filter_off": "Filter Keluarga Inaktif",
      "family_filter_on": "Filter Keluarga Aktif",
      "family_filter_18_warning": "Peringatan 18+",
      "family_filter": "Anda akan menonaktifkan filter keluarga. Ini akan memungkinkan Anda untuk melihat daftar video chat room yang hanya mengizinkan pengguna yang berusia 18 tahun atau lebih. Karena bilik memungkinkan pengguna 18 atau lebih tidak berarti membuat konten dewasa. Bacalah aturan bilik saat bergabung. Dengan mematikan keluarga filter, Anda konfirmasi bahwa Anda berusia 18 tahun atau lebih dan Anda menyetujui {0}. Apakah Anda yakin ingin melakukan ini?",
      "family_filter_link": "Persyaratan Layanan Camfrog",
      "leading": "Terkemuka",
      "cs": {
        "browser": {
          "search": {
            "desc": "Bilik percakapan yang cocok dengan pencarian anda"
          }
        }
      },
      "client": {
        "roombrowser": {
          "norooms": {
            "text": "Silakan coba lagi nanti"
          }
        }
      },
      "see_more": "Lihat Lagi",
      "load_more": "Ambil lagi",
      "follow_more_people": "Ikuti Lebih Banyak Lagi",
      "room_name": "Nama Bilik:%1%",
      "room_display_name": "Nama tampilan kamar: %1%",
      "room_category": "Kategori Bilik:%1%",
      "virtual_gift_status": "Status Hadiah Virtual:%1% poin",
      "custom_status": "Status Kebiasaan:%1%",
      "state": "Negara:%1%",
      "18_plus_rooms": "18+",
      "deaf_rooms": "Bisu",
      "audio_only": "Suara saja",
      "general": "Umum",
      "user_count": "Jumlah anggota aktif di dalam bilik percakapan ini",
      "cam_count": "Jumlah anggota yang menyiarkan kamera",
      "room_fan_rating": "Peringkat bintang untuk bilik berdasarkan jumlah penggemarnya",
      "tt_add_room_to_cl": "Tambahkan bilik ini ke daftar pertemanan saya",
      "tt_remove_room_to_cl": "Hapus bilik ini dari daftar kontak saya",
      "room_menu_report": "Laporkan bilik ini",
      "no_tags": "Bilik ini belum memiliki label apapun",
      "dialog": {
        "yes": "Ya",
        "no": "Tidak"
      },
      "clear_category_dialog": {
        "title": "Hapus riwayat",
        "content": "Apakah Anda ingin menghapus daftar ruang yang telah Anda kunjungi?"
      }
    }
  },
  "sr": {
    "message": {
      "video_chat_room_list": "Spisak Video Chat Sobe",
      "show_18_chat_room": "Prikazi 18+ Chat Sobe",
      "hide_18_chat_room": "Sakri 18+ Chat Sobe",
      "family_filter_off": "Porodicni Filter Iskljucen",
      "family_filter_on": "Porodicni Filter Ukljucen",
      "family_filter_18_warning": "18+ Upozorenje",
      "family_filter": "Vi zelite da onemogućite Famili Filter. To će vam omogućiti da vidite listu video chat sobe koje su dozvoljene samo korisnicima koji imaju 18 godina ili vise. Samo zato što omogućava korisnicima sobu sa 18 ili više godina, ne znači da soba dozvoljava sadržaj za odrasle. Molimo vas da pažljivo pročitate pravila sobe prilikom pristupanja. {0} Isključivanjem Famili Filter potvrđujete da imate 18 godina ili ste stariji i slažete se sa Camfrog uslovima korišćenja usluge. Da li ste sigurni da želite to da uradite?",
      "leading": "Vodeci",
      "cs": {
        "browser": {
          "search": {
            "desc": "Chat sobe koje se poklapaju sa vasim kriterijumima"
          }
        }
      },
      "client": {
        "roombrowser": {
          "norooms": {
            "text": "Molimo pokusajte kasnije"
          }
        }
      },
      "see_more": "Vidi Vise",
      "load_more": "Učitaj Vise",
      "room_name": "Ime Sobe: %1%",
      "room_category": "Kategorija Sobe: %1%",
      "virtual_gift_status": "Virtuelni Poklon Status: %1% poeni",
      "custom_status": "Uobicajeni Status: %1%",
      "state": "Drzava: %1%",
      "18_plus_rooms": "18+",
      "deaf_rooms": "Gluv",
      "audio_only": "Zvuk samo",
      "general": "Glavni",
      "user_count": "Broj aktivnih clanova u chat sobi",
      "cam_count": "Brojevi clanova koji prenose njihove kamere",
      "room_fan_rating": "Rejting zvezde zasnovan na fanovima sobe",
      "tt_add_room_to_cl": "Dodaj ovu sobu na moju kontakt listu",
      "tt_remove_room_to_cl": "Izbrisi ovu sobu sa moje kontakt liste",
      "room_menu_report": "Prijavi ovu sobu",
      "no_tags": "Ova soba jos uvek nema nijednu oznaku"
    }
  },
  "ms": {
    "message": {
      "video_chat_room_list": "Senarai Bilik Sembang Video",
      "family_filter": "nda akan mematikan penapis keluarga . Ini akan membolehkan anda untuk melihat senarai video chat room yang hanya membenarkan pengguna yang berusia 18 tahun atau lebih. kerana bilik hanya membolehkan pengguna 18 atau lebih tidak bererti bilik membolehkan kandungan dewasa. Bacalah aturan bilik ketika menyertai. Dengan memadamkan tapisan keluarga  anda pengesahan bahawa anda berumur 18 tahun atau lebih dan anda bersetuju dengan Syarat Perkhidmatan.{0} Adakah anda benar-benar ingin melakukan ini?",
      "family_filter_link": "Camfrog",
      "load_more": "memuatkan lebih",
      "18_plus_rooms": "18 tahun ke atas",
      "deaf_rooms": "pekak",
      "audio_only": "Hanya audio sahaja",
      "general": "Umum"
    }
  },
  "hr": {
    "message": {
      "video_chat_room_list": "Lista Video Chat Soba",
      "show_18_chat_room": "Prikazi 18+ Chat Sobe",
      "hide_18_chat_room": "Sakrij 18+ Chat Sobe",
      "family_filter_off": "Obiteljski Filter Iskljucen",
      "family_filter_on": "Obiteljski Filter Ukljucen",
      "family_filter_18_warning": "18+ Upozorenje",
      "family_filter": "Spremate se onemogućiti obitelji filter. To će Vam omogućiti da vidite popis video chat soba koje dopuštaju samo korisnike koji imaju 18 ili više godina. Samo zato što jedino dopusta korisnike koji imaju 18 ili vise godina, to ne znači da boravak omogućava sadržaj za odrasle. Molimo vas da pažljivo pročitate pravila sobe kada se pridružite. Isključivanjem obiteljskog filtera potvrdite da imate 18 godina ili ste stariji, i slažete se sa {0}. Jeste li sigurni da želite to učiniti?",
      "family_filter_link": "Camfrog Uvjeti pružanja usluge",
      "leading": "Vodeci",
      "cs": {
        "browser": {
          "search": {
            "desc": "Chat sobe koje se podudaraju sa vasim kriterijumima pretrage"
          }
        }
      },
      "client": {
        "roombrowser": {
          "norooms": {
            "text": "Molimo pokusajte kasnije"
          }
        }
      },
      "see_more": "Vidi više",
      "load_more": "Učitaj Više",
      "room_name": "Ime Sobe: %1%",
      "room_category": "nKategorija Sobe: %1%",
      "virtual_gift_status": "nStatus Virtuelnog Dara: %1% bodova",
      "custom_status": "nPrilagodjeni Status: %1%",
      "state": "nDržava: %1%",
      "18_plus_rooms": "18+",
      "deaf_rooms": "Gluho",
      "audio_only": "Audio samo",
      "general": "Opći",
      "user_count": "Broj aktivnih članova u chat sobi",
      "cam_count": "Broj članova koji emitiraju njihove kamere",
      "room_fan_rating": "Zvjezdice na temelju broja navijača u sobi",
      "tt_add_room_to_cl": "Dodaj ovu sobu na moj popis kontakata",
      "tt_remove_room_to_cl": "Izbrisi ovu sobu sa moje kontakt liste",
      "room_menu_report": "Prijavite ovu sobu",
      "no_tags": "Ova soba nema oznake još"
    }
  },
  "pt": {
    "message": {
      "video_chat_room_list": "Lista de Salas de Vídeo Chat",
      "show_18_chat_room": "Mostrar Salas de Chat +18",
      "hide_18_chat_room": "Esconder Salas Chat +18",
      "family_filter_off": "Filtro Familiar Off",
      "family_filter_on": "Filtro Familiar On",
      "family_filter_18_warning": "Aviso +18",
      "family_filter": "Você está prestes a desligar o filtro familiar. Isto irá permitir que você veja a listagem de salas +18. Tenha cuidado e leia atentamente as regras antes de entrar nestas salas. Ao desligar o filtro familiar você confirma que tem mais de 18 Anos de idade e concorda com os {0}. Tem a certeza que deseja prosseguir?",
      "family_filter_link": "Termos do Serviço Camfrog",
      "leading": "Liderando",
      "cs": {
        "browser": {
          "search": {
            "desc": "Salas de chat relacionados com a sua pesquisa"
          }
        }
      },
      "client": {
        "roombrowser": {
          "norooms": {
            "text": "Por favor tente mais tarde"
          }
        }
      },
      "see_more": "Ver Mais",
      "load_more": "Carregar Mais",
      "room_name": "Nome da Sala: %1%",
      "room_category": "Categoria da Sala: %1%",
      "virtual_gift_status": "Estado Presentes Virtuais: %1% pontos",
      "custom_status": "Status: %1%",
      "state": "Estado: %1%",
      "18_plus_rooms": "+ 18",
      "deaf_rooms": "Surdos",
      "audio_only": "Somente Audio",
      "general": "Geral",
      "user_count": "Numero de membros ativos na sala de chat",
      "cam_count": "Numero de membros transmitindo a sua camera",
      "room_fan_rating": "A classificação por estrelas com base no número de fãs da sala",
      "tt_add_room_to_cl": "Add esta sala á minha lista de contatos",
      "tt_remove_room_to_cl": "Remover isto da minha lista de contatos",
      "room_menu_report": "Reportar esta sala",
      "no_tags": "Esta sala ainda não tem nenhuma tag"
    }
  },
  "bg": {
    "message": {
      "video_chat_room_list": "Видео чат стая списък",
      "family_filter": "На път сте да забраните на семейството филтър. Това ще ви позволи да видите списък на видео чат стаи, които позволяват само на потребители, които са 18 или повече години. Това, че една стая само позволява на потребителите 18 или повече години не означава, че стаята дава възможност за пълнолетни. Моля, прочетете внимателно правилата на стаята, когато се присъедини. С изключване на семейството филтъра, който потвърди сте на 18-годишна възраст или по-големи и сте съгласни с {0}. Наистина ли искате да направите това?",
      "family_filter_link": "Условия за ползване Camfrog",
      "load_more": "Заредете още",
      "18_plus_rooms": "18+",
      "deaf_rooms": "Глухи",
      "audio_only": "Аудио само",
      "general": "Общи"
    }
  },
  "zh-s": {
    "message": {
      "home": "家",
      "promo_card": {
        "promote_room": "推广房间",
        "upgrade_sub": "升级你的订阅",
        "give_gift": "赠送礼物",
        "get_coins": "获取Camfrog金币",
        "manage_your_room": "管理您的房间"
      },
      "action_menu": {
        "join": "加入房间",
        "room_info": "房间信息",
        "gift": "发送虚拟礼物",
        "report": "举报此房间"
      },
      "search": {
        "search_room": "搜索房间",
        "no_results_found": "未找到结果"
      },
      "see_all": "看到所有",
      "filters": "筛选",
      "sort_by": "排序方式",
      "categories": "类别",
      "room_menu": "房间菜单列表",
      "go_back": "返回",
      "refresh_list": "刷新列表",
      "password_protected": "房间有密码保护",
      "empty_category": {
        "followed": {
          "header": "没有房间跟随",
          "link_label": "关注您最喜欢的{1},将他们添加到此列表并快速访问他们.",
          "link_text": "房间"
        },
        "recent": {
          "header": "还没有最近的房间",
          "link_label": "要查看您访问过的房间的历史记录,您必须先{1}.",
          "link_text": "参观一些房间"
        },
        "friends": {
          "header": "房间里没有朋友",
          "link_label": "{1}到您的联系人列表,查看他们所在的房间",
          "link_text": "添加更多朋友"
        },
        "default": {
          "header": "房间在哪里?",
          "link_label": "看来没有足够的能力讲那种语言了,在不同的语言列表或{1}下再次所搜.",
          "link_text": "创建自己的房间"
        }
      },
      "video_chat_room_list": "视频聊天室列表",
      "all_rooms2": "所有房间",
      "discover": "发现",
      "recent2": "最近的房间",
      "show_18_chat_room": "显示18+聊天室",
      "hide_18_chat_room": "隐藏18+聊天室",
      "family_filter_off": "关闭儿童保护过滤器",
      "family_filter_on": "开启儿童保护过滤器",
      "family_filter_18_warning": "18+ 警告",
      "family_filter": "你正要关闭家庭过滤器,此操作将打开一个新的视频聊天室列表,列表中的房间只允许18岁以上的用户加入.允许18岁以上用户进入的聊天室并不意味着允许成人内容.在加入聊天室时请仔细阅读聊天室规定.关闭家庭过滤器意味着你确认自己年满18岁并且同意{0}.你确定要这么做吗?",
      "family_filter_link": "服务条规",
      "leading": "主要的",
      "cs": {
        "browser": {
          "search": {
            "desc": "与您搜索字词相匹配的聊天室"
          }
        }
      },
      "client": {
        "roombrowser": {
          "norooms": {
            "text": "请稍后重试"
          }
        }
      },
      "see_more": "更多详情",
      "load_more": "加载更多房间",
      "follow_more_people": "关注更多人",
      "room_name": "聊天室名称：%1%",
      "room_display_name": "房间显示名称：%1%",
      "room_category": "聊天室类别：%1%",
      "virtual_gift_status": "虚拟礼物的状态：%1%积分",
      "custom_status": "自定义状态：%1%",
      "state": "状态：%1%",
      "18_plus_rooms": "18+",
      "deaf_rooms": "聋哑房",
      "audio_only": "只允许声音",
      "general": "普通",
      "user_count": "聊天室中活跃会员的人数",
      "cam_count": "正在通过摄像头进行播送的会员人数",
      "room_fan_rating": "星级评定",
      "tt_add_room_to_cl": "将这一聊天室添加到我的联系人列表",
      "tt_remove_room_to_cl": "从我的联系人列表中移除这一聊天室",
      "room_menu_report": "举报这一聊天室",
      "no_tags": "这间聊天室还没有添加任何标签",
      "dialog": {
        "yes": "是的",
        "no": "不"
      },
      "clear_category_dialog": {
        "title": "清楚历史记录",
        "content": "你想清除您访问过的房间列表吗?"
      }
    }
  },
  "fr": {
    "message": {
      "home": "ACCUEIL",
      "promo_card": {
        "promote_room": "Promouvoir un salon",
        "upgrade_sub": "Améliorer votre sub",
        "give_gift": "Offrir un cadeau",
        "get_coins": "Obtenez des Pièces Camfrog",
        "manage_your_room": "Gérez votre salon"
      },
      "action_menu": {
        "join": "Rejoindre le Salon",
        "room_info": "Infos du Salon",
        "gift": "Envoyer un Cadeau Virtuel",
        "report": "Signaler ce Salon"
      },
      "search": {
        "search_room": "Rechercher Salon",
        "no_results_found": "Aucun résultat trouvé"
      },
      "see_all": "Voir Tout",
      "filters": "Filtre",
      "sort_by": "Trier par",
      "categories": "Catégories",
      "room_menu": "Menu du salon",
      "go_back": "Retouner",
      "refresh_list": "Rafraîchir la list",
      "password_protected": "Le salon est protégée par un mot de passe",
      "empty_category": {
        "followed": {
          "header": "Aucun salon suivie",
          "link_label": "Suivez vos {1} préférés pour les ajouter à cette liste et visitez-les rapidement.",
          "link_text": "salons"
        },
        "recent": {
          "header": "Pas encore de salon récent",
          "link_label": "Pour voir l'historique des salons que vous avez visitées vous devez d'abord {1}.",
          "link_text": "Visiter quelques salons"
        },
        "friends": {
          "header": "Pas d'amis dans les salons",
          "link_label": "{1} à votre liste de contacts et voyez dans quelles salon ils se trouvent.",
          "link_text": "Ajouter plus d'amis"
        },
        "default": {
          "header": "Où sont les salons?",
          "link_label": "Il semble qu'il n'y ait pas assez de salons dans cette langue. Effectuez une nouvelle recherche dans une autre langue ou {1}.",
          "link_text": "commencez votre propre salon"
        }
      },
      "video_chat_room_list": "Répertoire des salles",
      "all_rooms2": "TOUT LES SALONS",
      "discover": "DECOUVRIR",
      "recent2": "SALONS RECENTS",
      "show_18_chat_room": "Afficher les Salons de t'chat 18+",
      "hide_18_chat_room": "Cacher les Salons 18+",
      "family_filter_off": "Filtre Familial Désactivé",
      "family_filter_on": "Filtre Familial Activé",
      "family_filter_18_warning": "Avertissement 18+",
      "family_filter": "Vous êtes sur le point de désactiver le filtre familial. Cela vous permettra d'afficher une liste de salles n'autorisant que les utilisateurs de 18 ans ou plus. Ce n'est pas parce que ces salles n'accueillent que des majeurs qu'elles ne contiennent que du contenu pour adultes. Veuillez lire attentivement les règles à l'entrée des salles. En désactivant le filtre familial, vous confirmez avoir 18 ans ou plus et vous acceptez de respecter les {0}. Êtes-vous certain(e) de vouloir le faire?",
      "family_filter_link": "conditions d'utilisation de Camfrog",
      "leading": "Avant",
      "cs": {
        "browser": {
          "search": {
            "desc": "Salons de discussion correspondant à vos termes de recherche"
          }
        }
      },
      "client": {
        "roombrowser": {
          "norooms": {
            "text": "S'il vous plaît essayez plus tard"
          }
        }
      },
      "see_more": "Plus",
      "load_more": "Plus de charge",
      "follow_more_people": "Suivre plus de personnes",
      "room_name": "Nom du salon: %1%",
      "room_display_name": "Nom d'affichage de la salle: %1%",
      "room_category": "Salon catégories: %1%",
      "virtual_gift_status": "Statut du présent virtuel: %1% points",
      "custom_status": "Statuts personnalisés: %1%",
      "state": "Etat: %1%",
      "18_plus_rooms": "18+",
      "deaf_rooms": "Malentendants",
      "audio_only": "Audio seulement",
      "general": "Général",
      "user_count": "Nombre de membres actifs dans le salon",
      "cam_count": "nombre de membres ayant une caméra",
      "room_fan_rating": "Nombre d’étoiles proportionnel aux utilisateurs présents",
      "tt_add_room_to_cl": "Ajouter ce salon a ma liste de contacts",
      "tt_remove_room_to_cl": "Retirer ce salon de ma liste de contacts",
      "room_menu_report": "Faire un rapport de ce salon",
      "no_tags": "Ce salon n'a pas de signatures pour l'instant",

      "dialog": {
        "yes": "Oui",
        "no": "Non"
      },
      "clear_category_dialog": {
        "title": "Effacer historique",
        "content": "Voulez-vous effacer la liste des salons que vous avez visitées?"
      }
    }
  },
  "ko": {
    "message": {
      "video_chat_room_list": "채팅방 목록",
      "all_rooms2": "모든 채팅방",
      "discover": "탐색",
      "recent2": "최근 채팅방",
      "show_18_chat_room": "18+ 채팅방 보기",
      "hide_18_chat_room": "18+ 채팅방 숨기기",
      "family_filter_off": "보호 기능 끄기",
      "family_filter_on": "보호기능 켬",
      "family_filter_18_warning": "경고 18+",
      "family_filter": "당신은 보호기능을 해제하려고 합니다. 비활성화 하면 18세이상의 채팅방 목록을 볼수있고 이용할수있습니다. 이분류의 채팅방이 반드시 성인 콘텐츠를 포함하고 있거나 부적절한 행동을 한다는것은 아니지만 채팅방이 18세이상만 허용하고있습니다. 방에 입장하실때 규칙을 주의깊게 읽어보시길바랍니다.18세 이상이고 보호기능을 해제 하며{0}에 동의합니다. 계속하시겠습니까?",
      "family_filter_link": "캠프로그 서비스 약관",
      "leading": "Top 채팅방",
      "cs": {
        "browser": {
          "search": {
            "desc": "검색 조건에 맞는 채팅방"
          }
        }
      },
      "client": {
        "roombrowser": {
          "norooms": {
            "text": "나중에 다시 시도해 주세요"
          }
        }
      },
      "see_more": "더 보기",
      "load_more": "더 보기",
      "follow_more_people": "더 많은 사람을 팔로우 해보세요",
      "room_name": "채팅방 이름: %1%",
      "room_display_name": "채팅방 표시 이름: %1%",
      "room_category": "채팅방 분류: %1%",
      "virtual_gift_status": "가상선물 상태: %1% 포인트",
      "custom_status": "현재상태: %1%",
      "state": "상태: %1%",
      "18_plus_rooms": "18+",
      "deaf_rooms": "소리없는 방",
      "audio_only": "오디오만",
      "general": "일반설정",
      "user_count": "채팅방에 활동중인 유저 수",
      "cam_count": "방송중인 캠이있는 유저수",
      "room_fan_rating": "채팅방 팬의수에 따른 등급",
      "tt_add_room_to_cl": "즐겨찾기에 이방을 추가",
      "tt_remove_room_to_cl": "즐겨 찾기에서 이 채팅방 제거",
      "room_menu_report": "채팅방 신고하기",
      "no_tags": "이 채팅방은 아직 어떠한 테그도 없습니다"
    }
  },
  "bs": {
    "message": {
      "video_chat_room_list": "Video Chat soba popis",
      "show_18_chat_room": "Prikaži 18+ Chat soba",
      "hide_18_chat_room": "Sakrij 18+ chat sobe",
      "family_filter_off": "Porodični filter isključivo",
      "family_filter_on": "Porodični filter uključivo",
      "family_filter_18_warning": "Upozorenje 18+",
      "family_filter": "Spremate se onemogućiti obitelji filter. To će Vam omogućiti da biste vidjeli popis video chat soba koje dopuštaju samo korisnici koji imaju 18 ili više godina. Samo zato što jedino omogućuje korisnicima soba 18 ili više godina, ne znači boravak omogućava sadržaja za odrasle. Molimo vas da pažljivo pročitate pravila sobi kada se pridružio. Isključivanjem obitelji filter što potvrdite da ste 18 godina ili stariji, a vi se slažete da {0}. Jeste li sigurni da želite to učiniti?",
      "family_filter_link": "Camfrog uvjeti pružanja usluge",
      "leading": "Vodeci",
      "cs": {
        "browser": {
          "search": {
            "desc": "Chat sobe podudaranje Vaše uvjete za pretraživanje"
          }
        }
      },
      "client": {
        "roombrowser": {
          "norooms": {
            "text": "Molimo pokusajte kasnije"
          }
        }
      },
      "see_more": "Vidi više",
      "load_more": "Učitaj više",
      "room_name": "Ime sobe: %1%",
      "room_category": "Soba kategorija: %1%",
      "virtual_gift_status": "Virtualni poklon status: %1% poena",
      "custom_status": "Prilagođeni status: %1%",
      "state": "Država: %1%",
      "18_plus_rooms": "18+",
      "deaf_rooms": "Gluh",
      "audio_only": "samo zvuka",
      "general": "Uopšteno",
      "user_count": "Broj aktivnih članova u chat sobe",
      "cam_count": "Broj članova emitiranje kameru",
      "room_fan_rating": "Zvjezdica na osnovu broja fanova u sobi",
      "tt_add_room_to_cl": "Dodaj ovu sobu na moju listu kontakata",
      "tt_remove_room_to_cl": "Ukloni ovu sobu iz moje liste kontakata",
      "room_menu_report": "Prijavi ovoj sobi",
      "no_tags": "Ova soba nema još bilo oznake"
    }
  },
  "es": {
    "message": {
      "home": "CASA",
      "promo_card": {
        "promote_room": "Promocionar una sala",
        "upgrade_sub": "Mejora tu sub",
        "give_gift": "Dar un regalo",
        "get_coins": "Consigue Monedas Camfrog",
        "manage_your_room": "Administra tu sala"
      },
      "action_menu": {
        "join": "Unirse a la Sala",
        "room_info": "Información de la Sala",
        "gift": "Enviar Regalo Virtual",
        "report": "Denunciar Esta Sala"
      },
      "search": {
        "search_room": "Búsque a Sala",
        "no_results_found": "No se han encontrado resultados"
      },
      "see_all": "Ver Todo",
      "filters": "Filtrar",
      "sort_by": "Ordenar por",
      "categories": "Categories",
      "room_menu": "Menú de la Sala",
      "go_back": "Regresa",
      "refresh_list": "Actualizar lista",
      "password_protected": "La Sala está protegida con contraseña",
      "empty_category": {
        "followed": {
          "header": "No hay Sala seguidas",
          "link_label": "Sigue tus {1} favoritas para agregarlas a esta lista y visitarlas rápidamente.",
          "link_text": "salas"
        },
        "recent": {
          "header": "Aún no hay habitaciones recientes",
          "link_label": "Para ver un historial de las salas que ha visitado, primero debe {1} salas.",
          "link_text": "visita algunas salas"
        },
        "friends": {
          "header": "No hay amigos en las salas",
          "link_label": "{1} a su lista de contactos y vea en qué salas están.",
          "link_text": "Agregar más amigos"
        },
        "default": {
          "header": "¿Dónde están las salas?",
          "link_label": "Parece que no hay suficientes salas en ese idioma. Buscar de nuevo en un idioma diferente o {1}.",
          "link_text": "comienza tu propia sala"
        }
      },
      "video_chat_room_list": "Lista de Salas de Vídeo Chat",
      "all_rooms2": "TODAS LAS SALAS",
      "discover": "DESCUBRIR",
      "recent2": "SALAS RECIENTES",
      "show_18_chat_room": "Mostrar Salas de Chat +18",
      "hide_18_chat_room": "Esconder Salas para Adultos 18+",
      "family_filter_off": "Filtro Familiar Desactivado",
      "family_filter_on": "Filtro Familiar Activado",
      "family_filter_18_warning": "Advertencia para 18+",
      "family_filter": "Vas a inutilizar el filtro familiar. Esto te permitirá ver una lista de salas de video chat que solo permiten la entrada a mayores de 18. El hecho de que una sala solo permita mayores de edad no significa que sea de contenido para adultos. Por favor lee detenidamente las normas de la sala cuando entres. Inutilizando el filtro familiar confirmas que eres mayor de 18 y que aceptas los {0}. ¿Estás seguro de hacer esto?",
      "family_filter_link": "Términos de Servicio de Camfrog",
      "leading": "Principal",
      "cs": {
        "browser": {
          "search": {
            "desc": "Las salas de chat que coinciden con su búsqueda."
          }
        }
      },
      "client": {
        "roombrowser": {
          "norooms": {
            "text": "Por favor inténtelo de nuevo más tarde"
          }
        }
      },
      "see_more": "Ver más",
      "load_more": "Mostrar Más",
      "follow_more_people": "Seguir Más Gente",
      "room_name": "Nombre de la Sala: %1%",
      "room_display_name": "Nombre Para Mostrar de la Sala: %1%",
      "room_category": "Categoría de Sala: %1%",
      "virtual_gift_status": "Regalo Virtual Estado: %1% puntos",
      "custom_status": "Estado Personalizado: %1%",
      "state": "Estado: %1%",
      "18_plus_rooms": "+18",
      "deaf_rooms": "Sordos",
      "audio_only": "Solo audio",
      "general": "General",
      "user_count": "Número de miembros activos en la sala de chat",
      "cam_count": "Número de miembros emitiendo en su cámara",
      "room_fan_rating": "Clasificación basada en el número de fans de la sala.",
      "tt_add_room_to_cl": "Añadir esta sala a mi lista de contactos",
      "tt_remove_room_to_cl": "Borrar esta sala de mi lista de contactos",
      "room_menu_report": "Informar sobre esta sala",
      "no_tags": "Esta sala aún no tiene ninguna etiqueta",
      "dialog": {
        "yes": "Si",
        "no": "No"
      },
      "clear_category_dialog": {
        "title": "Borrar historial",
        "content": "¿Quieres borrar la lista de salas que has visitado?"
      }
    }
  },
  "en": {
    "message": {
      "home": "HOME",
      "promo_card": {
        "promote_room": "Promote <br> a room",
        "upgrade_sub": "Upgrade <br> your sub",
        "give_gift": "Give <br> a gift",
        "get_coins": "Get <br> Camfrog <br> Coins",
        "manage_your_room": "Manage <br> your room"
      },
      "action_menu": {
        "join": "Join Room",
        "room_info": "Room Info",
        "gift": "Send Virtual Gift",
        "share": "Share this room",
        "report": "Report This Room",
      },
      "search": {
        "search_room": 'Search room',
        "no_results_found": 'No results found',
      },
      "see_all": "See All",
      "filters": "Filter",
      "sort_by": "Sort by",
      "reset_all": "Reset all",
      "categories": "Categories",
      "empty_category": {
        "followed": {
          "header": "No Rooms followed",
          "link_label": "Follow your favorite {1} to add them to this list and visit them quickly.",
          "link_text": "rooms"
        },
        "recent": {
          "header": "No recent rooms yet",
          "link_label": "In order to see a history of rooms you’ve visited you have to {1} first.",
          "link_text": "visit some rooms"
        },
        "friends": {
          "header": "No friends in rooms",
          "link_label": "{1} to your contact list and see which rooms they’re in.",
          "link_text": "Add more friends"
        },
        "default": {
          "header": "Where are rooms?",
          "link_label": "Looks like there are not enough rooms in that language. Search again under a different language or {1}.",
          "link_text": "start your own room"
        },
      },
      "discover": "DISCOVER",
      "family_filter_18_warning": "18+ Warning",
      "family_filter": "You are about to disable the family filter. This will allow you to view a list of video chat rooms that only allow users that are 18 or older. Just because a room only allows users 18 or older does not mean the room allows adult content. Please carefully read the rules of the room when joining. By turning off the family filter you confirm you are 18 years of age or older and you agree to the {0}. Are you sure you want to do this?",
      "family_filter_link": "Camfrog Terms of Service",

      "family_filter_off": "Family Filter Off",
      "family_filter_on": "Family Filter On",
      "show_18_chat_room": "Show 18+ Chat Rooms",
      "hide_18_chat_room": "Hide 18+ Chat Rooms",
      "user_count": "Number of active members in the chat room",
      "cam_count": "Number of members broadcasting their camera",
      "tt_add_room_to_cl": "Add this room to my contact list",
      "tt_remove_room_to_cl": "Remove this room from my contact list",
      "room_menu": "Room menu",
      "go_back": "Go back",
      "refresh_list": "Refresh list",
      "password_protected": "Room is password protected",
      "video_chat_room_list": "Video Chat Room List",
      "all_rooms2": "ALL ROOMS",
      "recent2": "RECENT ROOMS",
      "leading": "Top Rooms",
      "cs": {
        "browser": {
          "search": {
            "desc": "Chat rooms matching your search terms"
          }
        }
      },
      "client": {
        "roombrowser": {
          "norooms": {
            "text": "Please try again later"
          }
        }
      },
      "see_more": "See More",
      "load_more": "Load More",
      "follow_more_people": "Follow More People",
      "room_name": "Room Name: %1%",
      "room_display_name": "Room Display Name: %1%",
      "room_category": "Room Category: %1%",
      "virtual_gift_status": "Virtual Gift Status: %1% points",
      "custom_status": "Custom Status: %1%",
      "state": "State: %1%",
      "18_plus_rooms": "18+",
      "deaf_rooms": "Deaf",
      "audio_only": "Audio only",
      "general": "General",
      "room_fan_rating": "Star rating based on number of fans of the room",
      "room_menu_report": "Report this room",
      "no_tags": "This room does not have any tags yet",

      "dialog": {
        "yes": "Yes",
        "no": "No"
      },
      "clear_category_dialog": {
        "title": "Clear history",
        "content": "Do you want to clear the list of rooms you have visited?"
      }
    }
  }
}
