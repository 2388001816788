<template>
  <div data-testid="category-item"
       class="category-select"
       :class="{ 'category-disabled': !category.active, 'category-active': category.id === currentCategory.id && !isDiscoverPage }"
  >
    <img v-if="show_category_icon"
         data-testid="category-image"
         class="category-image"
         alt="category-icon"
         :src="formattedSrcIcon()"
         @error="hideCategoryIcon"
    >
    <span v-else class="category-icon-placeholder" data-testid="category-placeholder" />
    <span data-testid="category-name" class="category-name">{{ category.name }}</span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DiscoverPanelItem",

  props: {
    category: {
      type: Object,
      required: true,
      default: () => {}
    }
  },

  data() {
    return {
      blob: process.env.VUE_APP_BASE_BLOB,
      show_category_icon: true,
      categoryActive: {}
    }
  },

  computed: {
    ...mapGetters({
      currentCategory: 'currentCategory'
    }),
    isDiscoverPage() {
      return this.$route.name === 'discover'
    },
  },

  methods: {
    formattedSrcIcon() {
      return this.blob + '/svg/' + this.category.icon_id
    },

    hideCategoryIcon() {
      this.show_category_icon = false;
    }
  }
}
</script>

<style lang="scss">

.category-select {
  width: 150px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #333333;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: left;
  margin: 0 6px;
  padding: 7px 16px;

  &:hover {
    background-color: #F1F3F7;
    cursor: pointer;
  }
  > img {
    height: 24px;
    width: 24px;
    margin-right: 12px;
  }
  > * {
    vertical-align: middle;
  }
  .category-icon-placeholder {
    width: 18px;
    height: 18px;
    margin-right: 12px;
    background: #DADDE6;
    border-radius: 100%;
    display: inline-block;
  }
}
.category-disabled {
  &:hover {
    background-color: transparent;
    cursor: default;
  }
  .category-image {
    opacity: 0.5;
  }
  .category-name {
    color: #CCCCCC;
  }
  .category-icon-placeholder {
    background: #E6E4E4;;
  }
}
.category-active {
  background: #F1F3F7;
  border-radius: 4px;
}

</style>
