<template>
  <div id="nav-container" class="nav-container">
    <router-link class="nav-link" :to="{name: 'home'}">
      <span class="nav-link-icon icon-home" /><span>{{ $t('message.home') }}</span>
    </router-link>
    <router-link class="nav-link" :to="{name: 'discover'}">
      <span class="nav-link-icon icon-discover" /><span>{{ $t('message.discover') }}</span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "TabPages"
}
</script>

<style scoped lang="scss">
.nav-container {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  font-size: 17px;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 1px solid #E5E5E5;
  gap: 38px;

  .nav-link {
    color: #999999;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 2px 3px 18px;
    position: relative;
    .nav-link-icon {
      display: inline-block;
      background-color: #999999;
      margin-right: 15px;
    }
    .icon-home {
      width: 21px;
      height: 20px;
      -webkit-mask-image: url('~@/assets/images/room_browser/icon-home.svg');
      mask-image: url('~@/assets/images/room_browser/icon-home.svg');
    }
    .icon-discover {
      width: 18px;
      height: 23px;
      -webkit-mask-image: url('~@/assets/images/room_browser/icon-discover.svg');
      mask-image: url('~@/assets/images/room_browser/icon-discover.svg');
    }
  }
  .router-link-active {
    color: #51A71F;
    :after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      border-top: 4px solid;
      border-radius: 5px 5px 0 0;
      bottom: -1px;
      left: 0;
    }
    .nav-link-icon {
      background-color: #51A71F;
    }
  }
}
.nav-link:not(.router-link-active):hover {
  color: #666666;
}
.nav-link:not(.router-link-active):hover  {
  .nav-link-icon {
    background-color: #666666;
  }
}
</style>
