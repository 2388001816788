<template>
  <div id="room-browser-main">
    <TabPages />

    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { checkIsFollowingCategory, checkIsRecentlyCategory } from "@/room_browser/services/categories"
import TabPages from "@/room_browser/components/TabPages";
import { roomBrowserMixin } from "@/services/mixins";

export default {
  name: "Main",

  components: {
    TabPages
  },

  mixins: [ roomBrowserMixin ],

  computed: {
    ...mapGetters({
      globalParamsInitialized: 'globalParamsInitialized',
      globalFilters: 'globalFilters',
      isFollowedUpdated: 'isFollowedUpdated',
      isRecentlyUpdated: 'isRecentlyUpdated',
      allCategories: 'allCategories'
    }),

    followingCategory() {
      return this.allCategories.find(category => checkIsFollowingCategory(category))
    },

    recentlyVisitedCategory() {
      return this.allCategories.find(category => checkIsRecentlyCategory(category))
    },

    followingRooms() {
      return this.followingCategory && this.followingCategory.rooms.length > 0
    }
  },

  watch: {
    $route: {
      handler: function (newValue) {
        if (newValue.name !== 'discover_category') {
          this.$roomBrowserService.tabChange({name: newValue.name});
          this.reloadUpdatedCategories()
          this.$root.$refs['currentComponent'] = this
        }
      }
    },
  },

  created() {
    this.$root.$refs['currentComponent'] = this
    if (this.globalParamsInitialized === true) {
      this.updateLoader(true)
      this.load()
    }
  },

  methods: {
    ...mapActions({
      updateLoader: 'updateLoader',
      setRoomBrowserData: 'setRoomBrowserData',
      updateCategories: 'updateCategories',
      updateFollowedFlag: 'updateFollowedFlag',
      updateRecentlyVisitedFlag: 'updateRecentlyVisitedFlag',
      replaceCategory: 'replaceCategory',
      updateCategoryRoomsData: 'updateCategoryRoomsData',
      removeCategoryRoom: 'removeCategoryRoom'
    }),

    load(is_initial) {
      this.$roomBrowserService.getRoomBrowser(
        { global_filters: this.globalFilters },
        (response) => {
          this.setRoomBrowserData(response)
          this.$roomBrowserService.roomBrowserLoaded()
          this.updateFollowedFlag(false)
          this.updateRecentlyVisitedFlag(false)

          if (!this.followingRooms && this.isHomePage && is_initial) {
            this.$router.push({name: 'discover'})
          }

          if (this.$root.$refs.innerComponent) {
            this.$root.$refs.innerComponent.load()
          } else {
            this.updateLoader(false)
          }
        },
        (response) => {
          console.log('Main.vue. onFailure. response: ', response);
          /*this.updateLoader(false);*/
        }
      );
    },



    changeFollowed(data) {

      this.updateFollowedFlag(true)

      if (this.$root.$refs.innerComponent) {
        this.$root.$refs.innerComponent.changeFollowed(data)
      }

      const categories = JSON.parse(JSON.stringify(this.allCategories))

      for (const index in categories) {
        if (!data.user_followed && checkIsFollowingCategory(categories[index])) {
          this.removeCategoryRoom({index, room_id: data.room_id})
          continue
        }

        this.updateCategoryRoomsData({index, room_id: data.room_id, data: data})
      }

      if (data.user_followed && this.isHomePage) {
        this.updateFollowingCategory()
      }
    },

    async reloadUpdatedCategories() {
      if (!this.isHomePage) {
        return
      }

      if (this.isRecentlyUpdated) {
        await this.updateRecentlyVisitedCategory()
      }

      if (this.isFollowedUpdated) {
        await this.updateFollowingCategory()
      }
    },

    async updateFollowingCategory() {
      this.updateFollowedFlag(false)
      return this.getCategory(this.followingCategory)
    },

    async updateRecentlyVisitedCategory() {
      this.updateRecentlyVisitedFlag(false)
      this.getCategory(this.recentlyVisitedCategory)
    },

    getCategory(category) {
      return this.$roomBrowserService.getCategory({
          category_id: category.id,
          global_filters: this.globalFilters,
          category_preset: category.category_preset.value,
          category_filters: [],
          category_sorting: null,
          offset: 0
        },
        response => this.replaceCategory(response),
        response => console.log(response)
      )
    },
  },
}
</script>

<style scoped lang="scss">
.nav-container {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  font-size: 17px;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 1px solid #E5E5E5;
  .nav-link {
    color: #999999;
    margin-right: 38px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 2px 3px 18px;
    position: relative;
    .nav-link-icon {
      display: inline-block;
      background-color: #999999;
      margin-right: 15px;
    }
    .icon-home {
      width: 21px;
      height: 20px;
      -webkit-mask-image: url('~@/assets/images/room_browser/icon-home.svg');
      mask-image: url('~@/assets/images/room_browser/icon-home.svg');
    }
    .icon-discover {
      width: 18px;
      height: 23px;
      -webkit-mask-image: url('~@/assets/images/room_browser/icon-discover.svg');
      mask-image: url('~@/assets/images/room_browser/icon-discover.svg');
    }
  }
  .router-link-active {
    color: #51A71F;
    :after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      border-top: 4px solid;
      border-radius: 5px 5px 0 0;
      bottom: -1px;
      left: 0;
    }
    .nav-link-icon {
      background-color: #51A71F;
    }
  }
}
.nav-link:not(.router-link-active):hover {
  color: #666666;
}
.nav-link:not(.router-link-active):hover  {
  .nav-link-icon {
    background-color: #666666;
  }
}
</style>
