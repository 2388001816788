<template>
  <div class="no-rooms-placeholder">
    <img :src="placeholderImage" alt="button-reload">
    <h3 class="title" v-html="$t(title)" />
    <i18n class="link-label"
          :path="label"
          tag="label"
          :for="text"
    >
      <br>
      <a class="link-text" @click="navigateTo">{{ $t(text) }}</a>
    </i18n>
  </div>
</template>

<script>
import { getPlaceholderData } from "@/room_browser/services/categories";

export default {
  name: "CategoryEmptyPlaceholder",

  props: {
    categoryId: {
      type: Number,
      default: 0,
      required: true
    },
  },

  data() {
    return {
      placeholder: {},
      title: '',
      label: '',
      text: ''
    }
  },

  computed: {
    placeholderImage() {
      return require(`@/assets/images/room_browser/placeholders/${this.placeholder.icon_src}`)
    }
  },

  created() {
    this.placeholder = getPlaceholderData(String(this.categoryId))
    this.title = this.placeholder.i18n_path + '.header'
    this.label = this.placeholder.i18n_path + '.link_label'
    this.text = this.placeholder.i18n_path + '.link_text'
  },

  methods: {
    navigateTo() {
      this.placeholder.action()
    }
  }

}
</script>

<style lang="scss" scoped>
.no-rooms-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
  padding: 0 50px;
  background: #F1F3F7;
  opacity: .8;
  border-radius: 5px;
  color: #444545;
  line-height: 10px;
  img {
    width: 48px;
  }
  .title {
    margin: 14px 0 6px 0;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.0015em;
    color: #444545;
  }
  .link-label {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.0015em;
    color: #444545;
  }
  .link-text {
    color: #2E6DCB;
    &:hover {
      color: #0E57C4;
      cursor: pointer;
    }
  }
}
</style>
