// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import axios from 'axios';
import VueAxios from 'vue-axios';

axios.defaults.withCredentials = true;
Vue.use(VueAxios, axios);

import router_videochat from './router';
import router_room_info from "./router_room_info";
import store from './store';
import App from './App';

//import { RoomBrowserService as RoomBrowserServiceTest } from "@/room_browser/services/service_test";
import { RoomBrowserService as RoomBrowserServiceWeb  } from "@/room_browser/services/service_web_api";
//import { RoomBrowserService } from "@/room_browser/services/service_web";

Vue.prototype.$roomBrowserService = RoomBrowserServiceWeb;

let router;
///if (process.env.NODE_ENV === 'development') {
    //Vue.prototype.$roomBrowserService = RoomBrowserServiceTest;
//    Vue.prototype.$roomBrowserService = RoomBrowserServiceWeb;
//} else {
    //Vue.prototype.$roomBrowserService = RoomBrowserServiceWeb;
//}

// todo: consider future refactoring
if (process.env.NODE_ENV === 'production' && location.pathname.includes('/room-info')) {
    router = router_room_info;
} else {
    router = router_videochat;
}

// Services
import { External } from "@/external_data/videochat";
import {CONSTANTS} from '@/constants';
import {Helper} from '@/helper';
import {Protocol} from '@/protocol';
import {API} from '@/api/videochat';
import AJAX from '@/ajax';

Vue.prototype.$CONSTANTS = CONSTANTS;
Vue.prototype.$helper = Helper;
Vue.prototype.$protocol = Protocol;
Vue.prototype.$api = API;
Vue.prototype.$ajax = AJAX;
Vue.prototype.$external = External;
Vue.prototype.$appName = 'videochat';
Vue.prototype.router = router;

// Cookies
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

// Calendar
import VCalendar from 'v-calendar';
Vue.use(VCalendar);

// Tippy
import VueTippy, { TippyComponent } from "vue-tippy";

Vue.use(VueTippy, {
    directive: "tippy",
    placement : 'right',
    arrow : true,
    theme: 'room-browser',
    delay : [1000, 0]
});
Vue.component("tippy", TippyComponent);

// Prime Vue
import PrimeVue from 'primevue/config';
import "../style/prime_theme.scss";
import 'primevue/resources/primevue.min.css'; //core css
import 'primeicons/primeicons.css'; //icons

import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import InputSwitch from 'primevue/inputswitch';
import CascadeSelect from 'primevue/cascadeselect';
import AutoComplete from 'primevue/autocomplete';
import Menu from 'primevue/menu';
import ContextMenu from 'primevue/contextmenu';

Vue.component('Dialog', Dialog);
Vue.component('Button', Button);
Vue.component('Dropdown', Dropdown);
Vue.component('InputSwitch', InputSwitch);
Vue.component('CascadeSelect', CascadeSelect);
Vue.component('AutoComplete', AutoComplete);
Vue.component('Menu', Menu);
Vue.component('ContextMenu', ContextMenu);

Vue.use(PrimeVue);

// Swiper
import { Swiper, Navigation, Mousewheel, Keyboard } from 'swiper/js/swiper.esm';
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';
import 'swiper/css/swiper.min.css';
import 'swiper/css/swiper.css'

Swiper.use([Navigation, Mousewheel, Keyboard]);
Vue.use(getAwesomeSwiper(Swiper));

// Event bus
import { EventBus } from "@/event_bus";
window.EventBus = EventBus;

// i18n
import i18n from './i18n';

Vue.config.productionTip = false;

// Main App
/* eslint-disable no-new */
new Vue({
    el: '#videochat-pages',
    router,
    store,
    i18n,
    components: {
        App,
    },
    render: h => h(App),
});

// Custom Modal app
import Modal from '@/components/modules/Modal.vue'

let modal_id = 'vue-modal';

document.body.appendChild((function() {
    let modal = document.createElement('div');
    modal.id = modal_id;
    return modal.cloneNode(true);
})());

new Vue({
    el: '#' + modal_id,
    store,
    components: {
        Modal,
    },
    render: h => h(Modal),
});


// Custom Toast app
import Toast from '@/components/modules/Toast.vue'

let toast_id = 'vue-toast';

document.body.appendChild((function() {
    let modal = document.createElement('div');
    modal.id = toast_id;
    return modal.cloneNode(true);
})());

new Vue({
    el: '#' + toast_id,
    store,
    components: {
        Toast,
    },
    render: h => h(Toast),
});
