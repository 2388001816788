<template>
  <div data-testid="category-preset-item"
       class="category-preset-item"
       :class="{'category-preset-item-active': activePreset}"
       @click="handlerPreset(categoryPreset)"
  >
    <div data-testid="preset-item-name" class="preset-item-name">
      {{ categoryPreset.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: "CategoryPresetItem",

  props: {
    categoryPreset: {
      type: Object,
      required: true,
      default: () => {}
    },
    selectedCategoryPreset: {
      type: Number,
      required: false,
      default: null
    }
  },

  data() {
    return {
      selectedPreset: {}
    }
  },

  computed: {
    activePreset() {
      return this.categoryPreset.id === this.selectedCategoryPreset;
    }
  },

  created() {
    this.selectedPreset = this.categoryPreset
  },

  methods: {
    handlerPreset(preset) {
      this.$emit('changePreset', preset)
    }
  }

}
</script>

<style scoped lang="scss">
.category-preset-item {
  display: flex;
  border: 1px solid #D8DADF;
  border-radius: 75px;

  &:hover {
    cursor: pointer;
    background: #ECEEF4;
  }
  .preset-item-name {
    padding: 2px 14px;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #666666;
  }
}
.category-preset-item-active {
  color: rgba(0, 0, 0, 0.6);
  background: #B1F0AD;
  border: none;
  &:hover {
    color: #476045;
    background: #92D18E;
    cursor: pointer;
  }
}
</style>
