import Main from "@/room_browser/views/Main";
import Home from "@/room_browser/views/Home";
import Category from "@/room_browser/views/Category";
import DiscoverSingleCategory from "@/room_browser/views/DiscoverSingleCategory";
import DiscoverAllCategories from "@/room_browser/views/DiscoverAllCategories";
import Search from "@/room_browser/views/Search";
import Discover from "@/room_browser/views/Discover";

export default [
    {
        path: '',
        component: Main,
        children: [
            {
                path: 'home',
                name: 'home',
                component: Home,
                meta: { source : 'HOME' }
            },
            {
                path: 'discover',
                component: Discover,
                meta: { source : 'DISCOVER' },
                children: [
                    {
                        path: '',
                        name: 'discover',
                        component: DiscoverAllCategories,
                        meta: { source : 'DISCOVER' }
                    },
                    {
                        path: 'category/:id',
                        name: 'discover_category',
                        component: DiscoverSingleCategory,
                        meta: { source : 'ROOM_CATEGORY', backTo: 'discover' },
                    },
                ]
            },
            {
                path: '',
                redirect: 'home'
            },
        ],
    },
    {
        path: 'category/:id',
        name: 'category',
        component: Category,
        meta: { source : 'ROOM_CATEGORY' }
    },
    {
        path: 'search',
        name: 'search',
        component: Search,
        meta: { source : 'SEARCH' }
    }
]
