<template>
  <div id="modal-wrapper"
       :class="{ show: opened }"
  >
    <div id="modal-overlay"
         @click="close"
    />
    <div id="modal"
         @click.self="close"
    >
      <div id="modal-window"
           :data-type="type"
      >
        <div id="modal-close"
             :class="{ show: closeButton }"
             @click="close"
        >
          <svg width="14"
               height="14"
               viewBox="0 0 21 21"
               xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M0 1.45L1.45 0l9.02 9.02L19.49 0l1.45 1.45-9.02 9.02L21 19.55 19.55 21l-9.08-9.08-9.02 9.02L0 19.49l9.02-9.02L0 1.45z"
            />
          </svg>
        </div>
        <div id="modal-content">
          <h1>
            <span>{{ header }}</span>
          </h1>
          <component
            :is="content"
            :options="options"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: 'Modal',
        components: {

        },
        props: {
            // type: 'info' || ''
        },
        data() {
            return {
                html: document.documentElement,
                body: document.getElementsByTagName('body')[0],
            };
        },
        computed: {
            type() {
                return this.$store.state.modal.data.type || '';
            },
            opened() {
                return this.$store.getters.isOpenedModal;
            },
            closeButton() {
                return this.$store.state.modal.data.close_button || false;
            },
            header() {
                return this.$store.state.modal.data.header || '';
            },
            color() {
                if (this.header) {
                    return '#fff';
                }
                return '#acacac';
            },
            content() {
                if (this.$store.state.modal.data.content) {
                    return () => import(`@/views/modal_content/${this.$store.state.modal.data.content}`);
                }
                return '';
            },
            options() {
                if (this.$store.getters.isOpenedModal) {
                    this.open();
                }
                return this.$store.state.modal.data.options || [];
            },
        },
        created() {
            let self = this;
            this.$store.registerModule('modal', {
                state: {
                    _bus: [],
                    _opened: false,
                    data: {},
                },
                getters: {
                    isOpenedModal(state) {
                        return state._opened;
                    },
                },
                mutations: {
                    addModalToBus(state, data) {
                        state._bus.push(data);
                    },
                    getModalFromBus(state) {
                        if (state._bus.length) {
                            state.data = state._bus.shift();
                            state._opened = true;
                        }
                    },
                    updateModalParams(state, data) {
                        Object.keys(data)
                            .forEach((key) => {
                                if ( ! key.indexOf('_')) {
                                    return;
                                }
                                state.data[key] = data[key];
                            });
                    },
                    setModalOpened(state, opened) {
                        state._opened = !!opened;
                    },
                },
                actions: {
                    openModal(context, data) {
                        if (data) {
                            context.commit('addModalToBus', data);
                        }

                        if (context.state._opened) {
                            return;
                        } else {
                            context.commit('getModalFromBus');
                        }
                    },
                    updateModal(context, data) {
                        context.commit('updateModalParams', data);
                    },
                    closeModal(context) {
                        setTimeout(() => {
                            context.commit('setModalOpened', false);
                            context.dispatch('openModal');
                        }, 0 * 1000);
                        self.body.style.paddingRight = '0';
                        self.body.style.overflow = '';
                    },
                },
            });
        },
        methods: {
            open() {
                this.body.style.paddingRight = window.innerWidth - this.html.clientWidth + 'px';
                this.body.style.overflow = 'hidden';
            },
            close() {
                this.$store.dispatch('closeModal');
            },
        },
    }

    /*
    Modal call example

    this.$store.dispatch('openModal', {
        header: 'Upload avatar',
        content: 'UploadAvatar.vue',
        options: {
            image_current: this.avatar_src,
            formats: [
                'jpg',
                'jpeg',
                'png',
            ],
            resolutions: {
                width: 800,
                height: 800,
            }
        },
        close_button: true,
        result: '',
    });

    */
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    #modal-wrapper {
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: -100%;
        opacity: 0;
        z-index: 10;
        overflow: hidden;
        visibility: hidden;
    }
    #modal-wrapper.show {
        left: 0;
        opacity: 1;
        visibility: visible;
    }
    #modal {
        position: relative;
        height: 100%;
        min-height: 100%;
        padding: 0 30px;
        box-sizing: border-box;
        font-size: 0;
        white-space: nowrap;
        text-align: center;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    #modal:before {
        content: '';
        display: inline-block;
        height: 100%;
        min-height: 100%;
        width: 0;
        vertical-align: middle;
    }
    #modal-overlay {
        position: fixed;
        display: inline-block;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .5);
    }
    #modal-window {
        position: relative;
        display: inline-block;
        max-width: 100%;
        margin: 50px auto;
        white-space: normal;
        background-color: #fff;
        vertical-align: middle;
    }
    #modal-close {
        position: absolute;
        display: none;
        height: 40px;
        width: 40px;
        top: 0;
        right: 0;
        padding: 10px;
        box-sizing: border-box;
        background-color: transparent;
        cursor: pointer;
        z-index: 1;
        transition: background-color .2s;
    }
    #modal-close:hover {
        background-color: #fff;
    }
    #modal-close.show {
        display: inline-block;
    }
    #modal-close > svg {
        height: 100%;
    }
    #modal-close > svg > path {
        fill: #fff;
        transition: fill .2s;
    }
    #modal-close:hover > svg > path {
        fill: #59c959;
    }
    #modal-content > h1 {
        height: 40px;
        width: 100%;
        padding: 0 50px;
        box-sizing: border-box;
        background-color: #59c959;
    }
    #modal-content > h1 > span {
        display: inline-block;
        height: 100%;
        width: 100%;
        font-weight: 300;
        font-size: 16px;
        color: #fff;
        line-height: 40px;
        text-transform: uppercase;
        white-space: nowrap;
        text-overflow: ellipsis;
        user-select: none;
        overflow: hidden;
    }

    /* Modal with type 'info' */
    #modal-window[data-type="info"] {
        border-radius: 3px;
        background-color: #f7f7f7;
    }
    #modal-window[data-type="info"] > #modal-close > svg > path {
        fill: #acacac;
    }
    #modal-window[data-type="info"] > #modal-close:hover > svg > path {
        fill: #888;
    }
    #modal-window[data-type="info"] > #modal-content > h1 {
        height: auto;
        padding: 30px;
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        border-radius: 3px 3px 0 0;
        background-color: #fff;
    }
    #modal-window[data-type="info"] > #modal-content > h1 > span {
        font-weight: 400;
        font-size: 20px;
        color: #595959;
        line-height: 27px;
        text-transform: none;
        white-space: normal;
    }
</style>
