<template>
  <div id="room-card-container" class="room-card-container">
    <div id="room-card" class="room-card">
      <div class="header-room-category" :style="headerStyles">
        <img v-show="show_category_icon"
             data-testid="category-icon"
             :src="categoryIconSrc"
             alt="category-icon"
             @error="hideCategoryIcon"
        >
      </div>
      <div v-if="room.adult" class="room-adult" data-testid="adult">
        <div class="trap">
          <span>18+</span>
        </div>
      </div>
      <div class="avatar-row">
        <RoomAvatar :avatar-id="avatarId"
                    :flair-id="flairId"
                    :achievement-level="room.achievement_level"
                    data-testid="room-avatar"
                    @click.native="joinRoom"
        />
        <div class="room-card-actions">
          <RoomButtonFollow v-tippy
                            data-testid="button-follow"
                            :followers-count="followers_counter"
                            :followed="room.user_followed"
                            :content="tooltipFollowedText"
                            @click="changeFollow"
          />
          <button v-tippy="{ ignoreAttributes : true, hideOnClick: true }"
                  class="btn-actions"
                  :content="$t('message.room_menu')"
                  label="Toggle"
                  data-testid="button-actions"
                  @click="showRoomActionsMenu"
          />
          <Menu ref="menu"
                :model="roomActions"
                :popup="true"
                append-to="body"
          />
        </div>
      </div>
      <div class="nickname-topics-row">
        <h2 v-tippy
            data-testid="nickname"
            :title="displayName"
            :content="displayName"
            @click="joinRoom"
        >
          {{ displayName }}
        </h2>
        <div class="topics-wrap">
          <div v-if="room.has_password_protection"
               data-testid="password-protection"
               v-tippy
               class="lock-icon"
               :content="$t('message.password_protected')"
          >
            <img src="@/assets/images/room_browser/icon-lock.svg" alt="lock">
          </div>
          <p class="topics-list">
            <span v-for="(topic, index) in room.topics"
                  :key="index"
                  data-testid="topic"
                  :class="{'lock-icon-visible': room.has_password_protection}"
                  @click="searchTopic(topic)"
            >
              {{ topic }}
            </span>
          </p>
        </div>
      </div>
      <div class="room-card-footer">
        <div v-tippy
             class="footer-counter"
             :content="$t('message.cam_count')"
        >
          <img src="@/assets/images/room_browser/icon-cam.svg">
          <span data-testid="users-with-cam">{{ usersWithCamFormatted }}</span>
        </div>
        <div v-tippy
             class="footer-counter"
             :content="$t('message.user_count')"
        >
          <img src="@/assets/images/room_browser/icon-users.svg">
          <span data-testid="users-in-room">{{ usersInRoomFormatted }}</span>
        </div>
      </div>
      <img v-if="subscriptionId"
           data-testid="subscription-badge"
           class="subscription-badge"
           :src="subscriptionBadgeSrc"
           alt="subscription-badge"
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getTierName } from "@/services/room_subscription";
import RoomButtonFollow from "@/room_browser/components/room/RoomButtonFollow";
import RoomAvatar from "@/room_browser/components/room/RoomAvatar";
import { roomBrowserMixin } from "@/services/mixins";

export default {
  name: "Room",

  components: {
    RoomButtonFollow,
    RoomAvatar
  },

  mixins: [ roomBrowserMixin ],

  props: {
    room: {
      type: Object,
      required: true,
      default: () => {}
    },
    category: {
      type: Object,
      required: false,
      default: () => {}
    },
    isRecentlyVisited: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      show_action_menu: false,
      show_category_icon: true,
      followers_counter: this.room.fans,
      is_user_follower: null,
      blob: process.env.VUE_APP_BASE_BLOB,

      roomActions: [
        {
          label: this.$t('message.action_menu.join'),
          command: () => {
            this.joinRoom()
          }
        },
        {
          label: this.$t('message.action_menu.room_info'),
          command: () => {
            this.toRoomInfo()
          }
        },
        {
          label: this.$t('message.action_menu.gift'),
          command: () => {
            this.toGiveGift()
          }
        },
        {
          label: this.$t('message.action_menu.report'),
          command: () => {
            this.reportRoom()
          }
        }
      ]
    };
  },

  computed: {
    ...mapGetters({
      globalFilters: 'globalFilters',
      showShareRoom: 'showShareRoom',
    }),

    displayName() {
      return this.room.alias ? this.room.alias : this.room.nick;
    },
    usersWithCamFormatted() {
      return this.countThousands(this.room.users_with_cam);
    },
    usersInRoomFormatted() {
      return this.countThousands(this.room.users_in_room);
    },
    headerStyles() {
      return {
        background: this.room.category_color
      }
    },
    subscriptionBadgeSrc() {
      let tier = getTierName(this.room.subscription_id);

      if (this.room.subscription_id > 0) {
        tier = 'plus' //hack for old desktop clients
      }

      return require(`@/assets/images/room_browser/subscription/badge-${tier}.svg`)
    },
    categoryIconSrc() {
      return this.blob + '/svg/' + this.room.category_icon_id;
    },
    avatarId() {
      return this.room.avatar_id !== '' ? this.room.avatar_id : 0
    },
    flairId() {
      return this.room.flair_icon_id || 0;
    },
    subscriptionId() {
      return this.room.subscription_id > 10
    },
    tooltipFollowedText() {
      if (this.room.user_followed) {
        return this.$t('message.tt_remove_room_to_cl')
      } else {
        return this.$t('message.tt_add_room_to_cl')
      }
    },
  },

  created() {
    this.is_user_follower = this.room.user_followed
    if(this.isRecentlyVisited) {
      this.roomActions.push(
          {
            label: this.room.operations[0] ? this.room.operations[0].name : '',
            command: () => this.removeRoom()
          }
      )
    }

    if(this.showShareRoom) {
      this.roomActions.push(
        {
          label: this.$t('message.action_menu.share'),
          command: () => {
            this.shareThisRoom()
          }
        }
      )
    }
  },

  methods: {
    ...mapActions({
      updateLoader: 'updateLoader',
      setRoomBrowserData: 'setRoomBrowserData',
      setSearchResult: 'setSearchResult',
      setCurrentCategory: 'setCurrentCategory',
      replaceCategory: 'replaceCategory',
      updateSearchChips: 'updateSearchChips',
      updateRecentlyVisitedFlag: 'updateRecentlyVisitedFlag'
    }),

    joinRoom() {
      this.$roomBrowserService.joinRoom(this.room.id, this.room.nick)
      this.updateRecentlyVisitedFlag(true)
    },
    toRoomInfo() {
      this.$roomBrowserService.toRoomInfo(this.room.id, this.room.nick)
    },
    toGiveGift() {
      this.$roomBrowserService.toGiveGift(this.room.id, this.room.nick)
    },
    shareThisRoom() {
      this.$roomBrowserService.shareRoom(this.room.nick, this.room.alias, this.avatarId)
    },
    reportRoom() {
      this.$roomBrowserService.reportRoom(this.room.id, this.room.nick)
    },
    removeRoom() {
      this.updateLoader(true)
      this.$roomBrowserService.execRoomOperation(
          {
            category_id: this.category.id,
            room_id: this.room.id,
            operation_id: this.room.operations[0].id ? this.room.operations[0].id : 2,
            global_filters: this.globalFilters,
            category_preset: this.category.category_preset ? this.category.category_preset.value : null,
            category_filters: this.category.filters && this.category.filters.values ? this.category.filters.values : [],
            category_sorting:  this.category.sorting && this.category.sorting.value ? this.category.sorting.value : null,
            offset: this.offset

          },
          (response) => {
            console.log('Room.vue. . response: ', response);
            this.setCurrentCategory(response)
            this.replaceCategory(response)
            this.updateLoader(false)
          },
          () => {
            this.updateLoader(false)
          }

      )
    },

    changeFollow() {
      if (!this.isUserLoggedIn) {
        this.$roomBrowserService.actionUserLogin()
        return
      }

      this.$roomBrowserService.setRoomFollow(
        {
          room_id: this.room.id,
          room_nick: this.room.nick,
          follow: !this.room.user_followed
        }
      )
    },
    countThousands(count) {
      if (count >= 1000) {
        return (count / 1000).toFixed(1) + 'K'
      }
      return count;
    },
    showRoomActionsMenu(event) {
      this.$refs.menu.toggle(event);
    },
    closeActionMenu() {
      this.show_action_menu = false
    },
    hideCategoryIcon() {
      this.show_category_icon = false;
    },

    searchTopic(topic) {
      this.updateSearchChips([topic])
      const path = `search`
      if (this.$route.name !== path) {
        this.$router.push({name: path})
      }
    },

    formattedRoomName(name) {
      return name.replace(/_/g, '_\u00AD')
    },
  }
}
</script>

<style scoped lang="scss">
.room-card {
  display: flex;
  flex-direction: column;
  background: #F1F3F7;
  border-radius: 5px;
  width: 150px;
  height: 200px;
  position: relative;
}
.header-room-category {
  height: 20px;
  border-radius: 5px 5px 0 0;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center right;
  text-align: right;
}
.avatar-row {
  margin: -8px 0px 0 12px;
  display: flex;
  flex-direction: row;
}
.room-card-actions {
  position: relative;
  margin: 20px auto 0;
  text-align: center;
}
.btn-actions {
  margin-top: 5px;
  width: 19px;
  height: 24px;
  background: url("~@/assets/images/room_browser/btn-room-actions.svg");
  background-position-x: center;
  opacity: 0.5;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
}
.nickname-topics-row {
  margin-top: 13px;
  max-height: 60px;
  overflow: hidden;
  word-break: break-word;
  h2 {
    cursor: pointer;
    hyphens: manual;
    /* white-space: break-spaces; */
    -webkit-hyphenate-character: ' ';
  }
  .topics-wrap {
    min-height: 12px;
    padding: 0 12px;
    display: flex;
    position: relative;
    .lock-icon {
      position: absolute;
      top: -2px;
      margin-right: 7px;
    }
  }
}
h2 {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  max-height: 38px;
  letter-spacing: 0.01em;
  color: #2B2C2D;
  padding: 0 12px;
  margin-bottom: 8px;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
p.topics-list {
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  max-height: 28px;
  color: #87888A;
  overflow: hidden;
  word-break: break-word;
  .lock-icon-visible:first-child {
    margin-left: 15px;
  }
  span {
    &:hover {
      cursor: pointer;
      color: #0E57C4;
    }
    &:hover::before {
      color: #87888A;
    }
  }
}
.topics-list > span:not(:first-child):before {
  content: ', ';
}
.room-card-footer {
  display: flex;
  flex-direction: row;
  font-weight: 700;
  font-size: 11px;
  line-height: 15px;
  color: #82878C;
  position: absolute;
  bottom: 0;
  padding: 0 0 8px 12px;
}
.footer-counter {
  min-width: 39px;
}
.footer-counter > * {
  vertical-align: middle;
  margin: 0 1px;
}
.subscription-badge {
  position: absolute;
  right: 0;
  bottom: 8px;
}
.room-adult {
  position: absolute;
  z-index: 100;
  .trap {
    width: 42px;
    height: 42px;
    background: #FF705C;
    clip-path: polygon(45% 0, 100% 0, 0 100%, 0 45%);
    span {
      display: block;
      padding-top: 1px;
      padding-left: 3px;
      transform: rotate(-45deg);
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 15px;
      letter-spacing: 0.015em;
      color: #FFFFFF;
    }
  }
}
</style>
