const tiers = {
  0: {
    name: 'free'
  },
  10: {
    name: 'green'
  },
  20: {
    name: 'purple'
  },
  30: {
    name: 'gold'
  },
  40: {
    name: 'platinum'
  },
  50: {
    name: 'diamond'
  },
  60: {
    name: 'vip'
  },
  70: {
    name: 'plus'
  },
};

export const getTierName = (id) => {
  return tiers[id] ? tiers[id].name : '';
};
