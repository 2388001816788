import Vue from 'vue';
import VueRouter from 'vue-router';
import roomBrowserRoutes from '@/room_browser/router/routes'

const RoomPublicProfile = () => import("./views/RoomPublicProfile");
const RoomBrowserWeb    = () => import("./views/room_browser/RoomBrowserWeb");
const General           = () => import("./views/room_profile/General");
const ViewAllTab        = () => import("@/views/room_settings/ViewAllTab");
const ViewAllGifts      = () => import("@/views/room_settings/ViewAllGifts");

const InfoGifts = () => import("./views/room_profile/InfoGifts.vue");
const InfoRules = () => import("./views/room_profile/InfoRules.vue");

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'hash',
  routes: [
    {
      path: '',
      component: RoomBrowserWeb,
      children: roomBrowserRoutes
    },
    {
      path: '/room-info',
      component: RoomPublicProfile,
      children: [
        {
          name: 'General',
          path: '',
          component: General,
          children: [
            {
              path: 'gifts',
              component: InfoGifts,
            },
            {
              path: 'rules',
              component: InfoRules,
            },
            {
              path: '',
              redirect: 'gifts',
            }
          ]
        },
        {
          path: 'gifts/all',
          component: ViewAllTab,
          children: [
            {
              path: '',
              component: ViewAllGifts,
              props: { public_view: true }
            }
          ]
        },
      ],
    },
    {
      path: '*',
      redirect: '',
    }
  ],
  scrollBehavior () {
    return new Promise(resolve => {
      this.app.$root.$once('scrollTo', (selector) => {
        resolve({ selector: selector })
      })
    })
  }
});

export default router;
