<template>
  <!-- /22832329239/Camfrog_rooms_incontent_DWEB -->
  <google-tag :gpt-id="gptId" style="min-width: 320px; min-height: 50px;" />
</template>

<script>
import GoogleTag from "@/components/Ads/GoogleTag";

export default {
  name: "RoomBrowserInContent",
  components: { GoogleTag },
  props: {
    slotKey: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      slots: {
        1: 'div-gpt-ad-1710425800776-0',
        2: 'div-gpt-ad-1710425954470-0',
        3: 'div-gpt-ad-1710426011201-0'
      }
    }
  },
  computed: {
    gptId() {
      return this.slots[this.slotKey] || this.slots[1];
    }
  },
}
</script>

<style scoped>
 div {
 }
</style>
