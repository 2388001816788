import { EventBus } from "@/event_bus";
import axios from 'axios';


let api = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_WEB_API + '/room-browser',
  headers: {
    'X-CSRF-TOKEN' : window.csrf || '',
    'Accept' : 'application/json'
  }
})

const BASE_URL = process.env.VUE_APP_BASE_URL,
  PROFILES_URL = process.env.VUE_APP_PROFILES_BASE_URL,
  VIDEOCHAT_URL = process.env.VUE_APP_VIDEOCHAT_BASE_URL

api.interceptors.response.use(function (response) {
  console.log('service_web_api.js. . response: ', response);
  return response;
}, function (error) {
  //const response = error.response;
  console.log('service_web_api.js. error: ', error);
  return Promise.reject(error);
});

let search_history = [];

export const RoomBrowserService = {
  async initialize() {
    let result

    try {
      const response = await api.get(`/settings`)
      result = response.data
      EventBus.$emit("setGlobalParams", result);
    } catch (error) {
      result = {}
      //todo: handle error
    }
  },
  // eslint-disable-next-line no-unused-vars
  async getRoomBrowser(params, onSuccess, onFailure) {

    let result, callback

    try {
      const response = await api.post(`/rooms`, params)
      result = response.data
      EventBus.$emit("setLanguagesList", result.languages);
      callback = onSuccess
    } catch (error) {
      result = {}

      callback = onFailure
    }

    return callback(result);
  },
  // eslint-disable-next-line no-unused-vars
  async getCategory(params, onSuccess, onFailure) {
    let result, callback

    try {
      const response = await api.post(`/rooms/categories/${params.category_id}`, params)
      result = response.data
      callback = onSuccess
    } catch (error) {
      result = {}
      callback = onFailure
    }
    return callback(result)
  },

  // actions promoted cards
  actionRoomStart() {
    window.open(`${PROFILES_URL}/room_settings.php`)
  },
  actionRoomPromote() {
    window.open(`${BASE_URL}/store/#room_upgrades=room_promotion`)
  },
  actionRoomUpgrade() {
    window.open(`${BASE_URL}/upgrade/room.php`)
  },
  actionRoomGiveGift() {
    window.open(`${BASE_URL}/store/#gifts`)
  },
  actionRoomGetCoins() {
    window.open(`${BASE_URL}/coins/`)
  },
  actionUserSubscriptionUpgrade() {
    window.open(`${BASE_URL}/register/`)
  },

  actionUserLogin() {
    window.location = `${BASE_URL}/login.php?ret=` + location.href;
  },

  // actions room
  joinRoom(room_id, room_nick) {
    window.location = 'camfrog://join_room/?name=' + encodeURIComponent(room_nick)
  },
  toRoomInfo(room_id, room_nick) {
    window.open(`${VIDEOCHAT_URL}/room-info/` + encodeURIComponent(room_nick) + '#/room-info')
  },
  toGiveGift(room_id, room_nick) {
    window.open(`${BASE_URL}/store/?seek=` + encodeURIComponent(room_nick) + '#gifts')
  },
  shareRoom(room_nick /*, alias, avatar_id*/) {
    const deeplink = 'camfrog://join_room/?name=' + encodeURIComponent(room_nick)
    window.open(`${BASE_URL}/main/join-room/?deeplink=` + encodeURIComponent(deeplink))
  },
  reportRoom(room_id, room_nick) {
    window.open(`${BASE_URL}/inappropriate-room.php?nick=` + encodeURIComponent(room_nick))
  },
  async setRoomFollow(params) {
    try {
      if (params.follow) {
        await api.put(`/follow/${params.room_id}`)
      } else {
        await api.delete(`/follow/${params.room_id}`)
      }
      EventBus.$emit("changeFollowed", {
        "room_id": params.room_id,
        "room_name": params.room_nick,
        "user_followed": params.follow
      });
    } catch (error) {
      //
    }
    //window.location = 'camfrog://add_contact/?name=' + encodeURIComponent(params.room_nick)
  },

  // search
  async searchRooms(params, onSuccess, onFailure) {
    let result, callback

    try {
      const response = await api.post(`/rooms/search`, params)
      result = response.data
      search_history = result.search_history || []
      callback = onSuccess
    } catch (error) {
      result = {}
      callback = onFailure
    }
    return callback(result)
  },
  async getSearchAutocomplete(params, onSuccess) {
    return onSuccess(search_history)
  },

  // other events
  openTermsOfService() {
    window.open(`${BASE_URL}/terms.phtml`);
  },

  async execCategoryOperation(params, onSuccess, onFailure) {
    let result, callback

    try {
      const response = await api.post(`/rooms/operation`, params)
      result = response.data
      callback = onSuccess
    } catch (error) {
      result = {}
      callback = onFailure
    }
    return callback(result)
  },

  execRoomOperation(params, onSuccess, onFailure) {
    return this.execCategoryOperation(params, onSuccess, onFailure)
  },

  tabChange() {},

  highlightUI() {},

  roomBrowserLoaded() {},

  changeFollowed() {},
};
