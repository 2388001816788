<template>
  <!-- /22832329239/Camfrog_1x1 -->
  <google-tag gpt-id="div-gpt-ad-1708520365809-0" />
</template>

<script>
import GoogleTag from "@/components/Ads/GoogleTag";
export default {
  name: "Google1x1",
  components: { GoogleTag },
}
</script>
