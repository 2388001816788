import Store from '@/store/room_settings'
import {External} from '@/external_data/videochat'
import {Helper} from '@/helper'

export const API = {
    config: {
        store: Store,
        external: External,
        helper: Helper,

        base_url: External.url.profiles + 'ajax/room/',
        urls: {
            get_gifts: 'get_gifts.php',
        },
    },
};
