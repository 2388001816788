<template>
  <div id="room-browser-discover">
    <Category ref="category" :standalone="false" />
  </div>
</template>

<script>
import Category from "@/room_browser/views/Category";
import {roomBrowserMixin} from "@/services/mixins";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "DiscoverSingleCategory",

  components: {
    Category
  },

  mixins: [ roomBrowserMixin ],

  computed: {
    ...mapGetters({
      showLoader: 'showLoader',
      globalParamsInitialized: 'globalParamsInitialized',
    }),
  },

  mounted() {
    if (this.globalParamsInitialized === true) {
      this.updateLoader(true)
      this.$refs['category'].load()
    }
  },

  methods: {
    ...mapActions({
      updateLoader: 'updateLoader'
    })
  },

}

</script>

<style lang="scss">
#room-browser-discover {
  border-top: 1px solid #E5E5E5;
}
.category-title {
  margin: 32px 0 5px;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: #333333;
  transition: 0.5s ease all;
}

</style>
