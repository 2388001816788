<template>
  <button class="btn-banner" :style="backgroundPromo" @click="promo.callback()">
    <span v-html="$t(promo.phrase)" />
  </button>
</template>

<script>
export default {
  name: "HomePromoCard",

  props: {
    promo: {
      type: Object,
      required: true,
      default: () => {}
    }
  },

  computed: {
    backgroundPromo() {
      return {
        backgroundImage: `url("${require('@/assets/images/room_browser/' + this.promo.background)}")`,
      }
    },
  },
}
</script>

<style scoped lang="scss">
.btn-banner {
  height: 107px;
  flex-basis: 107px;
  max-width: 176px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  border-radius: 10px;
  cursor: pointer;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0.0015em;
  color: #fff;
  text-align: left;
  display: flex;
  padding: 10px 12px 0 10px;
  position: relative;
  flex-grow: 1;

  &:hover:not(:active):after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    opacity: 0.2;
  }

  span {
    display: inline-block;
    width: 100%;
    overflow: hidden;
  }
}
</style>
