<template>
  <div id="room-browser-category-view" class="room-browser-category-view">
    <Loader v-if="showLoader" />

    <div v-else class="search-result-container">
      <div class="search-result-header">
        <h1>{{ search_result.name }}</h1>
        <LocalFilters :filters="search_result.filters"
                      :sorting="search_result.sorting"
                      @changeSort="changeSort"
                      @changeFilters="changeFilters"
                      @resetFiltersAndSort="resetFiltersAndSort"
        />
      </div>
      <div class="search-result-chips">
        <SearchChip v-for="(chip, index) in searchChips"
                    :key="index"
                    :chip="chip"
                    @removeChip="removeChip"
        />
      </div>
      <div class="search-result-rooms">
        <Room v-for="room in search_result.rooms" :key="room.id" :room="room" />
        <transition name="fade">
          <div v-if="isLoadMore" class="loader-wrap">
            <Loader />
          </div>
        </transition>
      </div>
      <div v-if="!searchResultRooms" class="search-no-result">
        <div class="image-wrapper">
          <img src="@/assets/images/room_browser/search-empty.svg" alt="no-result">
        </div>
        <span>{{ $t('message.search.no_results_found') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader";
import LocalFilters from "@/room_browser/components/LocalFilters";
import SearchChip from "@/room_browser/components/search/SearchChip";
import Room from "@/room_browser/components/room/Room";
import { updateRooms } from "@/room_browser/services/categories"

export default {
  name: "Search",

  components: {
    Loader,
    LocalFilters,
    SearchChip,
    Room
  },

  data() {
    return {
      isLoadMore: false,
      search_result: {},
      offset: 0,
      scrollReady: true
    };
  },

  computed: {
    ...mapGetters({
      showLoader: 'showLoader',
      globalParamsInitialized: 'globalParamsInitialized',
      globalFilters: 'globalFilters',
      searchResult: 'searchResult',
      searchChips: 'searchChips'
    }),

    needLoadMore() {
      return this.search_result.request_next_rooms_from < this.search_result.total_rooms
    },

    searchResultRooms() {
      if (this.searchResultLength && this.search_result.rooms) {
        return this.search_result.rooms.length > 0
      }
      return false
    },

    searchResultLength() {
      return Object.keys(this.search_result).length > 0
    }
  },

  watch: {
    searchResult: {
      handler: function(newValue) {
        this.offset = newValue.request_next_rooms_from
      },
      deep: true
    },
    searchChips: {
      handler: function () {
        this.load()
      }
    }
  },

  created() {
    this.$root.$refs['currentComponent'] = this
    if (this.globalParamsInitialized === true) {
      this.updateLoader(true)
      this.load()
    }
    this.search_result = JSON.parse(JSON.stringify(this.searchResult))
    this.offset = this.search_result.request_next_rooms_from
  },

  mounted() {
    this.loadMore()
  },

  destroyed() {
    window.onscroll = () => {};
  },

  methods: {
    ...mapActions({
      updateLoader: 'updateLoader',
      setSearchResult: 'setSearchResult',
      updateSearchQuery: 'updateSearchQuery',
      updateSearchChips: 'updateSearchChips',
      updateFollowedFlag: 'updateFollowedFlag'
    }),

    load() {
      this.offset = 0
      this.getSearchResult()
    },

    changeFollowed(data) {
      this.updateFollowedFlag(true)
      updateRooms(this.search_result.rooms, data)
    },

    loadMore() {
      window.onscroll = () => {
        let scrollHeight = Math.max(
            document.body.scrollHeight, document.documentElement.scrollHeight,
            document.body.offsetHeight, document.documentElement.offsetHeight,
            document.body.clientHeight, document.documentElement.clientHeight
        );

        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
        let bottomOfWindow = scrollTop + window.innerHeight === scrollHeight;

        if (bottomOfWindow && this.needLoadMore && this.scrollReady) {
          this.scrollReady = false
          this.isLoadMore = true
          this.getSearchResult(true)
        }
      };
    },

    mergingRooms(rooms) {
      this.search_result.rooms = rooms.concat()
      for (let i = 0; i < this.search_result.rooms.length; ++i ) {
        for (let j = i + 1; j < this.search_result.rooms.length; ++j ) {
          if (this.search_result.rooms[i].id === this.search_result.rooms[j].id) {
            this.search_result.rooms.splice(--j, 1)
          }
        }
      }
      this.scrollReady = true
      return this.search_result.rooms
    },

    changeFilters(filters) {
      this.search_result.filters.values = filters
      this.load()
    },

    changeSort(sort) {
      this.search_result.sorting.value = sort
      this.load()
    },

    resetFiltersAndSort(params) {
      this.search_result.sorting.value = params.sort_id
      this.search_result.filters.values = params.filters
      this.load()
    },

    removeChip(chip) {
      let search_query = this.searchChips

      let chipIndex = search_query.indexOf(chip);
      if (chipIndex !== -1) {
        search_query.splice(chipIndex, 1);
      }

      //this.updateSearchChips(search_query)
    },

    getSearchResult(is_load_more) {

      if (!this.searchChips.length) {
        this.$router.go(-1);
        this.setSearchResult({})
        return
      }

      this.$roomBrowserService.searchRooms(
          {
            search_query: this.searchChips,
            global_filters:  this.globalFilters,
            category_filters: this.search_result.filters ? this.search_result.filters.values : [],
            category_sorting:  this.search_result.sorting ? this.search_result.sorting.value : null,
            offset: this.offset,
          },
          (response) => {
            this.setSearchResult(response)
            let rooms = this.search_result.rooms
            this.search_result = response
            if (is_load_more) {
              this.mergingRooms(rooms.concat(this.search_result.rooms))
            }

            this.isLoadMore = false
            this.$roomBrowserService.roomBrowserLoaded()
            this.updateLoader(false)
          },
          (response) => {
            console.log('Search.vue. getSearchResult.  error: ', response);
            this.isLoadMore = false
            this.updateLoader(false);
          }
      )
    }
  }
}
</script>

<style scoped lang="scss">
.room-browser-category-view {
  height: 100%;
  min-height: 500px;
  position: relative;

  .search-result-container {
    .search-result-header {
      margin: 25px 0;
      display: flex;
      justify-content: space-between;
      h1 {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.0015em;
        color: #333333;
      }
    }
    .search-result-chips {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 24px;
    }
    .search-result-rooms {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      min-height: 200px;
      position: relative;
      margin-left: -12px;
      .room-card-container {
        margin-bottom: 24px;
        margin-left: 12px;
        @media screen and (max-width: 1130px) {
          margin-bottom: 12px;
        }
      }
    }
  }
  .search-no-result {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    .image-wrapper {
      margin: 0 auto;
      img {
        max-width: 100%;
        margin-bottom: 10px;
      }
    }
    span {
      font-family: Segoe UI,serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #999999;
    }
  }
}
</style>
