<template>
  <div class="room-card-avatar-container">
    <div class="room-card-avatar">
      <img v-if="hasAvatar"
           :src="avatarSrc"
           data-testid="avatar"
           alt="room-avatar"
           @error="resetAvatar"
      >
      <img v-else
           src="@/assets/images/room_browser/avatar-placeholder.png"
           data-testid="avatar-placeholder"
           alt="avatar-placeholder"
      >
    </div>
    <div v-if="hasLevel || hasFlair" class="alevel-flair-container">
      <img v-if="hasLevel"
           class="image-alevel"
           alt="alevel-icon"
           data-testid="achievement-level"
           :src="levelSrc"
           @error="resetAlevel"
      >
      <img v-if="hasFlair"
           class="image-flair"
           alt="flair-icon"
           :src="flairSrc"
           data-testid="flair"
           @error="resetFlair"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'RoomAvatar',

  props: {
    avatarId: {
      type: Number,
      required: false,
    },
    achievementLevel: {
      type: Number,
      required: false,
    },
    flairId: {
      type: Number,
      required: false,
    }
  },

  data() {
    return {
      avatar_id: 0,
      alevel: this.achievementLevel,
      flair: this.flairId
    }
  },

  computed: {
    hasAvatar() {
      return this.avatar_id > 0;
    },
    hasFlair() {
      return this.flair > 0;
    },
    hasLevel() {
      return this.alevel > 0;
    },
    avatarSrc() {
      return `${process.env.VUE_APP_BASE_BLOB}/${this.avatarId}`;
    },
    flairSrc() {
      return `${process.env.VUE_APP_BASE_BLOB}/dpi128/${this.flairId}`;
    },
    levelSrc() {
      return `${process.env.VUE_APP_STATIC_CONTENT_DOMAIN_1}/images/alevel/and/${this.achievementLevel}.svg`;
    }
  },

  created() {
    this.avatar_id = this.avatarId
  },

  methods: {
    resetAvatar() {
      this.avatar_id = 0;
    },
    resetFlair() {
      this.flair = 0;
    },
    resetAlevel() {
      this.alevel = 0;
    }
  }
}
</script>

<style scoped lang="scss">
.room-card-avatar-container {
  height: max-content;
  position: relative;
  border-radius: 5px;
  cursor: pointer;
  background: #fff;
}
.room-card-avatar {
  border-radius: 5px;
  width: 80px;
  height: 80px;
  overflow: hidden;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.alevel-flair-container {
  border-radius: 0 23px 23px 0;
  padding: 5px 5px 4px 13px;
  background: rgba(241, 243, 247, 0.8);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  position: absolute;
  bottom: -10px;
  left: -12px;
}
.image-alevel, .image-flair {
  vertical-align: top;
}
.image-alevel {
  height: 16px;
}
.image-flair {
  width: 16px;
  padding-left: 2px;
  padding-top: 1px;
}
</style>
