import Vue from 'vue';
import Vuex from 'vuex';
import room_browser from '@/room_browser/store/modules/room_browser'

Vue.use(Vuex);

const room_profile = {
  state: {
    profile: null,
    gifts_all: null
  },
  getters: {
    roomProfile: (state) => {
      return state.profile;
    },
    giftsAllCache: (state) => {
      return state.gifts_all;
    },
  },
  mutations: {
    setRoomProfile(state, params) {
      state.profile = params;
    },
    setGiftsAllCache(state, param) {
      state.gifts_all = param;
    },
  },
  actions: {},
}

export default new Vuex.Store({
  modules: {
    profile: room_profile,
    room_browser
  }
})
