<template>
  <div class="chip-item">
    <div class="chip-item-name" data-testid="chip">
      {{ chip }}
    </div>
    <span class="chip-item-close" @click="removeChip" />
  </div>
</template>

<script>
export default {
  name: "SearchChip",

  props: {
    chip: {
      type: String,
      required: false,
      default: 'test'
    }
  },

  methods: {
    removeChip() {
      this.$emit('removeChip', this.chip)
    }
  }
}
</script>

<style scoped lang="scss">
.chip-item {
  position: relative;
  display: flex;
  border: 1px solid #D8DADF;
  background: #EBEFF4;
  padding-right: 20px;
  border-radius: 40px;
  margin-bottom: 8px;
  &:not(:last-child) {
    margin-right: 8px;
  }
  &:hover {
    cursor: pointer;
    background: #E0E5EC;
    color: #5E6062;
  }
  .chip-item-name {
    padding: 4px 14px;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #666666;
  }
  .chip-item-close {
    position: absolute;
    right: 3px;
    top: 6px;
    width: 31px;
    height: 15px;
    opacity: 0.3;
  }
  .chip-item-close:hover {
    opacity: 0.9;
  }
  .chip-item-close:before, .chip-item-close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 15px;
    width: 2px;
    background-color: #333;
  }

  .chip-item-close:before {
    webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .chip-item-close:after {
    webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

}

</style>
