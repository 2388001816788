<template>
  <div :id="gptId" />
</template>

<script>
import { displayAds } from "@/components/Ads/ads";
export default {
  name: "GoogleTag",
  props: {
    gptId: {
      type: String,
      required: true
    }
  },
  mounted() {
    displayAds(this.gptId);
  }
}
</script>

<style scoped>

</style>
