<template>
  <div>
    <button type="button"
            class="like-btn"
            data-testid="follow-button"
            :class="{'active' : followed}"
            @click="emitEvent"
    >
      <svg width="19"
           height="18"
           viewBox="0 0 22 18"
           fill="none"
           xmlns="http://www.w3.org/2000/svg"
      >
        <path fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15.5383 1C13.5031 1 11.756 2.15404 11 3.93008C10.2441 2.15404 8.49694 1 6.46182 1C3.73459 1 1.76191 3.1541 1.52372 5.89874C1.14847 10.2222 5.2739 13.5 10.9882 17C16.7261 13.5 20.8516 10.2222 20.4764 5.89874C20.2382 3.1541 18.2655 1 15.5383 1Z"
              stroke="#999999"
              stroke-width="1.5"
              stroke-linejoin="round"
        />
      </svg>
    </button>
    <p>{{ followersCountFormatted }}</p>
  </div>
</template>

<script>
export default {
  name: "RoomButtonFollow",
  props: {
    followersCount: {
      type: Number,
      required: false,
      default: null
    },
    followed: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    followersCountFormatted() {
      if (this.followersCount >= 1000) {
        return (this.followersCount / 1000).toFixed(1) + 'K'
      }
      return this.followersCount;
    }
  },
  methods: {
    emitEvent() {
      this.$emit('click')
    }
  }
}
</script>

<style scoped lang="scss">
.like-btn {
  width: 19px;
  height: 18px;
  cursor: pointer;
  &:hover svg path {
    fill: #989DA2;
    fill-opacity: 0.2;
  }
}
.active svg path {
  fill: #E54D00;
  stroke: #E54D00;
}
.active:hover svg path {
  fill: #CA4400;
  stroke: #CA4400;
  fill-opacity: 1;
}
p {
  font-weight: 600;
  font-size: 10px;
  text-align: center;
  color: #989DA2;
}
</style>
