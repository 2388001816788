import Vue from "vue";

const TOP_CATEGORY_ID = 1;
const FOLLOWING_CATEGORY_ID = 3;
const RECENTLY_CATEGORY_ID  = 4;
const FRIENDS_CATEGORY_ID   = 5;

const PROMOTED_PRESET_ID = 4;

const placeholders_map = {
  [FOLLOWING_CATEGORY_ID]: {
    icon_src: "ghost.png",
    i18n_path:  "message.empty_category.followed", // usage example: $t(i18n_path + ".header"),
    action: () => {
      Vue.prototype.router.push({name: 'discover'})
      console.log('follow placeholder');
    }
  },
  [RECENTLY_CATEGORY_ID]: {
    icon_src: "m_eyes.png",
    i18n_path:  "message.empty_category.recent",
    action: () => {
      Vue.prototype.router.push({name: 'discover'})
      console.log('recent placeholder');
    }
  },
  [FRIENDS_CATEGORY_ID]: {
    icon_src: 'handshake.png',
    i18n_path:  "message.empty_category.friends",
    action: () => {
      Vue.prototype.$roomBrowserService.joinRoom(
        process.env.VUE_APP_MAIN_ROOM_ID,
        process.env.VUE_APP_MAIN_ROOM_NICK,
      );
      console.log('friends placeholder');
    }
  },
  "default": {
    icon_src: 'cloud.png',
    i18n_path:  "message.empty_category.default",
    action: () => {
      Vue.prototype.$roomBrowserService.actionRoomStart();
      console.log('default placeholder');
    }
  }
};

export const getPlaceholderData = category_id => {
  return placeholders_map[category_id] || placeholders_map.default;
}

export const checkIsPromotedCategory = (category) => {
  return category.id === TOP_CATEGORY_ID && category.category_preset && category.category_preset.value && category.category_preset.value === PROMOTED_PRESET_ID;
}

export const checkIsFollowingCategory = (category) => {
  return category.id === FOLLOWING_CATEGORY_ID
}

export const checkIsRecentlyCategory = (category) => {
  return category.id === RECENTLY_CATEGORY_ID
}

export const updateRooms = (rooms, params) => {
  for(let room in rooms) {
    if (rooms[room].id === params.room_id) {
      rooms[room].user_followed = params.user_followed
    }
  }
  return rooms;
}
