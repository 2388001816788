<template>
  <div v-if="visibleSortAndFilters" data-testid="local-filters">
    <Button
      type="button"
      class="filters-btn"
      @click="openFiltersMenu"
    >
      <img src="@/assets/images/icons/filters.svg" alt="filter-icon">
      <span class="filters-btn-text">
        {{ $t('message.filters') }}
        <span v-if="filtersLength" data-testid="filter-text">(<span class="filter-label">{{ filtersText }}</span>)</span>
      </span>
    </Button>
    <ContextMenu
      ref="menu"
      :model="items"
      append-to="menu-block-open"
    />
    <div id="menu-block-open" />
  </div>
</template>

<script>

export default {
  name: "LocalFilters",

  props: {
    filters: {
      type: Object,
      required: true,
      default: () => {}
    },
    sorting: {
      type: Object,
      required: true,
      default: () => {}
    },
  },

  data() {
    return {
      filter_list: [],
      filter_values: [],
      defaultSotValue: null,
      items: [
        {
          label: this.$t('message.sort_by'),
          items: []
        },
        {
          separator: true
        },
        {
          separator: true
        },
        {
          label: this.$t('message.reset_all'),
          command: () => this.resetAll(),
          disabled: () => this.disableResetBtn
        },
      ]
    };
  },

  computed: {
    visibleSortAndFilters() {
      if (this.filters && this.sorting && this.filters.list && this.sorting.list) {
        return this.filters.list.length && this.sorting.list.length
      }
      return false
    },

    disableResetBtn() {
      return !this.filtersLength && (this.defaultSotValue === this.sortItem.value)
    },

    filtersExcludeAny() {
      return this.filter_values.filter(filter => filter.value_id !== 0);
    },

    filtersLength() {
      return this.filtersExcludeAny.length > 0
    },

    filtersText() {
      const length = this.filtersExcludeAny.length;
      let text = '';

      if (length === 1) {
        const name = this.formattedNameFilter(this.filtersExcludeAny[0]);
        text += name;
      } else {
        text += length;
      }
      return text;
    },

    sortItem() {
      return this.items[0]
    }
  },

  created() {
    this.initSorting()
    this.initFilters()
  },

  methods: {
    /**
     * Open filters and sort menu
     * @param { Object } event Browser event
     */
    openFiltersMenu(event) {
      this.$refs.menu.toggle(event);
    },

    /**
     * Initialization filters and formatted filters object
     */
    initFilters() {
      if (this.filters && this.filters.values && this.filters.list) {
        this.filter_values = JSON.parse(JSON.stringify(this.filters.values))
        this.filter_list = JSON.parse(JSON.stringify(this.filters.list))
      }
      this.filter_list.forEach((filter, i) => {
        this.renameField(filter, 'name', 'label')
        this.renameField(filter, 'values', 'items')
        this.items.splice(i + 2, 0, filter);
        filter.items.forEach(item => {
          this.addFilterIcon(this.filter_values, item, filter)
          this.renameField(item, 'name', 'label')
          item.command = (event) => {
            this.addFilterIcon(filter.items, event.item)
            this.changeFilters(filter.items, event, filter)
          }
        })
      })
    },

    /**
     * Add icon for selected filter
     * @param { Array } field Array filters 2 level menu
     * @param { Object } filter Filter object
     * @param { Object } item Selected filter
     */
    addFilterIcon(field, item, filter = null) {
      field.filter(fil => {
        fil.icon = ''
        if ((filter && fil.filter_id === filter.id && fil.value_id === item.id) || (!filter && fil.id === item.id)) {
          item.icon = 'pi pi-check'
        }
      })
    },

    /**
     * Change filters field and emit for parent component
     * @param { Array } filterArray Array filters 2 level menu
     * @param { Object } event Browser event
     * @param { Object } filter Filter 1 level
     */
    changeFilters(filterArray, event, filter) {
      this.filter_values.filter(item => {
        if(item.filter_id === filter.id) {
          item.value_id = event.item.id
        }
        delete item['icon']
      })
      this.$emit('changeFilters', this.filter_values);
    },

    /**
     * Initialization sort and formatted sort object
     */
    initSorting() {
      if (this.sorting && this.sorting.list) {
        this.sortItem.items = JSON.parse(JSON.stringify(this.sorting.list))
        this.sortItem.value = this.sorting.value ? this.sorting.value : null
      }

      this.defaultSotValue = this.sortItem.value

      for (let item of this.sortItem.items) {
        this.renameField(item, 'name', 'label')
        this.addSortIcon(this.sortItem.value)
        item.command = (event) => {
          this.addSortIcon(event.item.id)
          this.changeSort(event)
        }
      }
    },

    /**
     * Add icon for selected sort item
     * @param { Number } sort_id ID sort item
     */
    addSortIcon(sort_id) {
      this.sortItem.items.filter(sort_item => {
        sort_item.icon = ''
        if (sort_id === sort_item.id) {
          sort_item.icon = 'pi pi-check'
        }
      })
    },

    /**
     * Emit sort item id for parent component
     * @param { Object } event Browser event
     */
    changeSort(event) {
      this.sortItem.value = event.item.id
      this.$set(this.items, 0, this.sortItem)
      this.$emit('changeSort', event.item.id)
    },

    /**
     * Rename filters or sort fields for PrimeVue component
     * @param { Object } parentObject Parent object
     * @param { String } oldValue Old key value
     * @param { String } newValue New key value
     */
    renameField(parentObject, oldValue, newValue) {
      parentObject[newValue] = parentObject[oldValue];
      delete parentObject[oldValue];
    },

    /**
     * Reset all filter and sort values
     */
    resetAll() {
      this.filter_values.forEach((item) => {
        item.value_id = 0
        delete item['icon']
      })

      this.filter_list.forEach(filter => {
        filter.items.forEach(item => {
          item.icon = ''
          this.addFilterIcon(this.filter_values, item, filter)
        })
      })

      this.sortItem.value = this.defaultSotValue
      this.$set(this.items, 0, this.sortItem)
      this.addSortIcon(this.defaultSotValue)

      this.$emit('resetFiltersAndSort', {
        sort_id: this.defaultSotValue,
        filters: this.filter_values
      })
    },

    /**
     * Formatted name filter
     * @param { Object } selectFilter selected filter
     * @returns {string} filter label
     */
    formattedNameFilter(selectFilter) {
      let label = ''
      this.filter_list.forEach(filter => {
        filter.items.forEach(item => {
          if (selectFilter.filter_id === filter.id && selectFilter.value_id === item.id) {
            label = item.label
          }
        })
      })
      return label
    },
  }
}
</script>

<style lang="scss" scoped>
.category-sorting {
  margin-left: 16px;
  min-width: 188px;
}
.category-filters,
.category-sorting {
  background-color: #EBEFF4;
  color: #666666;
  border-radius: 40px;
  border: none;

  &:hover {
    background-color: #E0E5EC;
    color: #5E6062;
  }

  .category-filter-value {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    padding: 4px 0 4px 15px;
    color: inherit;
    letter-spacing: 0.0015em;

    .filter-text {
      max-width: 20ch;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      vertical-align: bottom;
      overflow: hidden;
    }
  }

  .p-cascadeselect-item-group {
    padding-right: 13px;
  }

  .filters-item {
    width: 100%;
    font-size: 14px;
    line-height: 38px;
    color: #191818;

    > * {
      padding: 0 13px;
    }
    .filter-item-value {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      //padding: 0 32px 0 21px;
      color: #191818;

      img {
        width: 14px;
        visibility: hidden;
      }
      span {
        margin-left: 21px;
      }
      &.checked img {
        visibility: visible;
      }
    }
  }
}

.filters-btn {
  padding: 6px 15px 6px 12px;
  height: 30px;
  background: #F7F7F7;
  border-radius: 40px;
  .filters-btn-text {
    margin-left: 8px;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.0015em;
    color: #666666;
    .filter-label {
      max-width: 15ch;
      display: inline-block;
      vertical-align: bottom;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      @media screen and (max-width: 540px) {
        max-width: 5ch;
      }
    }
  }
}
.filters-btn.p-button {
  border: 1px solid transparent;
}
.filters-btn.p-button:enabled:hover {
  background: #F7F7F7;
  color: unset;
  border: 1px solid #D8DADF;
}
.filters-btn.p-button:enabled:focus {
  background: #F7F7F7;
  color: unset;
  border: 1px solid #D8DADF;
}

#menu-block-open {
  position: relative;
  .p-contextmenu {
    top: 0 !important;
    left: 0 !important;
    @media screen and (max-width: 960px) {
      top: auto !important;
      left: unset !important;
      right: 0;
    }
    .p-menuitem-icon {
      position: absolute;
    }
    .p-submenu-list {
      .p-menuitem-text {
        margin-left: 30px;
      }
    }
  }
}
</style>

