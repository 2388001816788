import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    avatar_id: '',
    avatar_base64: '',
    nickname: '',
    alias: '',
    color: '',
    flair_id: '',
    change_name: {},
    mobile_verification_enabled: false,
    room_running: false,
    gifts_all: null,
    cloud_params: null,
    remaining_roomcast_credits: null,
  },
  getters: {
    giftsAllCache: (state) => {
      return state.gifts_all;
    },
  },
  mutations: {
    // Change name custom modal
    setChangeNameParams(state, params) {
      state.change_name = params;
    },
    updateChangeNameParams(state, params) {
      Object.keys(params)
        .forEach((key) => {
          state.change_name[key] = params[key];
        });
    },

    // Avatar
    updateAvatarId(state, param) {
      state.avatar_id = param;
    },
    updateAvatarBase64(state, param) {
      state.avatar_base64 = param;
    },

    // Nickname
    updateNickname(state, param) {
      state.nickname = param;
    },

    // Alias
    updateAlias(state, param) {
      state.alias = param;
    },

    updateMobileVerificationStatus(state, param) {
      state.mobile_verification_enabled = param;
    },

    setGiftsAllCache(state, param) {
      state.gifts_all = param;
    },

    setRoomRunningStatus(state, param) {
      state.room_running = param;
    },

    setRemainingRoomcastCredits(state, param) {
      state.remaining_roomcast_credits = param;
    },

    setCloudParams(state, param) {
      state.cloud_params = param;
    },

    setColor(state, param) {
      state.color = param;
    },

    setFlairId(state, param) {
      state.flair_id = param;
    }
  },
  actions: {

  },
});
