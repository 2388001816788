<template>
  <button class="room-promote-card" @click="promoteRoom">
    <img alt="promote-icon" src="@/assets/images/room_browser/icon-room-promote.svg">
    <span v-html="$t('message.promo_card.promote_room')" />
  </button>
</template>

<script>
export default {
  name: "RoomPromoteCard",

  methods: {
    promoteRoom() {
      this.$roomBrowserService.actionRoomPromote();
    }
  }
}
</script>

<style lang="scss" scoped>
.room-promote-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 150px;
  height: 200px;
  background-color: #FFBE00;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFF;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    background-color: #FFCD38;
  }
  &:active {
    background-color: #FFBE00;
  }
  img {
    height: 36px;
  }
  span {
    margin-top: 21px;
    max-width: 120px;
  }
}
</style>
