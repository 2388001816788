<template>
  <div>
    <h1 class="category-title">
      {{ $t('message.categories') }}
    </h1>
    <div ref="stickyWrapper" class="discover-categories-buttons-wrapper">
      <DiscoverPanel class="discover-categories-buttons" />
    </div>
    <router-view />
  </div>
</template>

<script>
import DiscoverPanel from "@/room_browser/components/category/DiscoverPanel";

export default {
  name: "Discover",
  components: {
    DiscoverPanel
  },

  mounted() {
    const app = this.$appName || '';

    if (app !== 'videochat') {
      this.initializeObserver();
    }
  },

  methods: {
    initializeObserver() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: [0, 1.0]
      };

      const observer = new IntersectionObserver(this.handleIntersection, options);
      observer.observe(this.$refs.stickyWrapper);
    },

    handleIntersection(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.$refs.stickyWrapper.classList.remove('sticky');
        } else {
          this.$refs.stickyWrapper.classList.add('sticky');
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
@keyframes fade-in {
  0% {
      opacity: 0;
    }
100% {
     opacity: 1;
   }
}

.discover-categories-buttons-wrapper {
  position: relative;
  display: flex;
  justify-content: center;

  .discover-categories-buttons {
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }
}

.sticky .discover-categories-buttons {
  min-width: 636px;
  max-width: 960px;
  width: 71.5%;
  position: fixed;
  top: 69px;
  left: 50%;
  transform: translateX(-50%);
  right: 0;
  z-index: 989;
  background-color: white;
  animation-name: fade-in;
}

@media screen and (max-width: 1345px) {
  .sticky .discover-categories-buttons {
    width: 799px;
  }
}

@media screen and (max-width: 1130px) {
  .sticky .discover-categories-buttons {
    width: 637px;
  }
}
</style>
