<template>
  <div id="videochat-site">
    <router-view />
    <google1x1 />
  </div>
</template>

<script>
import Google1x1 from "@/components/Ads/Google1x1.vue";

export default {
  name: "App",
  components: { Google1x1 }
}
</script>

<style>
.align-center {
  text-align: center;
}
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.link-common {
  color: #3D95CC;
  cursor: pointer;
}
</style>
